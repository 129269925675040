import React, { FC } from "react";
import styled from 'styled-components';
import { RED } from '../../config/Constants';
import { DivFlexCenCen } from '../utility/flex';

type Props = {
  errorMessages: string[],
}

export const ErrorMessages:FC<Props> = (props: Props) => {
  const { errorMessages } = props;

  return(
    <>
      {
        errorMessages.map((message, index) =>
          <DivFlexCenCen style={{margin: '1rem 0'}} key={index}>
            <PErrorMessage>{message}</PErrorMessage>
          </DivFlexCenCen>
        )
      }
    </>
  )
}

const PErrorMessage = styled.p`
  color: ${RED};
`;
