import axios from 'axios';

const csrfToken:()=>string|null = () => {
  const meta = document.querySelector<HTMLMetaElement>('meta[name=csrf-token]');
  return meta && meta.content;
};

const axiosWithRails = axios.create({ headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRF-Token': csrfToken() } });

export { axiosWithRails as axios };
