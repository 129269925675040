import styled from "styled-components";
import { LINK_BULE, ORANGE_PRIMARY } from "../../config/Constants";


export const ALink = styled.a`
  color: ${LINK_BULE};
  text-decoration: none;

  &:hover{
    color: ${ ORANGE_PRIMARY }
  }
`

export const PLink = styled.p`
  color: ${LINK_BULE};
  text-decoration: none;

  &:hover{
    color: ${ ORANGE_PRIMARY }
  }
`

export const SpanLink = styled.span`
  color: ${LINK_BULE};
  text-decoration: none;

  &:hover{
    color: ${ ORANGE_PRIMARY }
  }
`
