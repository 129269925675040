import React, { FC } from "react";
import styled from 'styled-components';
import { BORDER, ORANGE_PRIMARY, WHITE } from '../../config/Constants';

type Props = {
  name: string;
  stringValues: (string|number)[][];
  value?: string|number;
  onChangeAction: React.FormEventHandler<HTMLFormElement>;
}

export const SelectForm:FC<Props> = (props: Props) => {
  const { stringValues, value, name, onChangeAction } = props;

  const defaultValue = (value: string|number|undefined) => {
    if(value == null) {
      return ''
    } else {
      return  value;
    }
  }


  return(
    <>
      <SelectTag onChange={onChangeAction} value={defaultValue(value)} name={name}>
        {
          stringValues.map((stringValue, index) =>(
            <option key={index} value={stringValue[1]}>{stringValue[0]}</option>
          ))
        }
      </SelectTag>
    </>
  )
}

const SelectTag= styled.select`
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background-color: ${WHITE};
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`
