import React, { FC } from "react";
import styled from 'styled-components';
import { Contest } from "../../types/contest";
import { RED, TEXT_SECONDARY } from '../../config/Constants';
import { DivWrap } from "../utility/wrap";

type Props = {
  contests: Contest[],
  questionSetId: number,
}

export const ContestTerm:FC<Props> = (props: Props) => {
  const { contests, questionSetId } = props;

  const contest:Contest = contests.find(c => c.questionSetId === questionSetId);
  if(contest == null) return null;

  return(
    <>
      <PTerm>{contest.term}</PTerm>
    </>
  )
}

const PTerm = styled.div`
  text-align: center;
  color: ${TEXT_SECONDARY};
  font-size: 0.875rem;
`
