import styled from "styled-components";


export const DivFlex = styled.div`
  display: flex;
`

export const DivFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const DivFlexCen = styled.div`
  display: flex;
  justify-content: center;
`
export const DivFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DivFlexEndEnd = styled(DivFlexEnd)`
  align-items: flex-end;
`

export const DivFlexNorEnd = styled.div`
  display: flex;
  justify-content: normal;
  align-items: flex-end;
`

export const DivFlexCenCen = styled(DivFlexCen)`
  align-items: center
`
export const DivFlexNorCen = styled.div`
  display: flex;
  justify-content: normal;
  align-items: center;
`

export const DivFlexCenEnd = styled(DivFlexCen)`
  align-items: flex-end;
`
export const DivFlexSpbet = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DivFlexSpbetTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
`

export const DivFlexSpbetEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const DivFlexSpbetCen = styled(DivFlexSpbet)`
  align-items: center
`

export const DivFlexSparo = styled.div`
  display: flex;
  justify-content: space-around;
`


export const DivFlexSparoCen = styled(DivFlexSparo)`
  align-items: center
`
