import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { ResetPasword } from './resetPasword';


document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-reset-password-index-root')!;
  if(domToBinding == null) return

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <ResetPasword/>
      </DivOverallWrap>
    </>,
    domToBinding);
});
