import React from 'react';
import ReactDOM from 'react-dom';
import { Contest } from '../../../types/contest';
import { AnswerSetResult } from './answerSetResult';

const pageName = 'answer-sets-show-root'

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById(pageName)!;
  if(domToBinding == null) return

  type Props = {
    answerSetId: number;
    contest: Contest|null;
  }

  const props:Props = JSON.parse(domToBinding.dataset.json!);

  ReactDOM.render(
    <>
      <AnswerSetResult {...props}/>
    </>,
    document.getElementById(pageName));
});
