import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { Reactivate } from './reactivate';


document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-users-reactivate-root')!;
  if(domToBinding == null) return
  const props= JSON.parse(domToBinding.dataset.json!);

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <Reactivate {...props}/>
      </DivOverallWrap>
    </>,
    domToBinding);
});
