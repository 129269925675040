// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "jquery"
import "channels"
import "../react/pages/question_sets/index"
import "../react/pages/question_sets/show"
import "../react/pages/answer_sets/show"
import "../react/pages/users/new"
import "../react/pages/users/complete"
import "../react/pages/users/reactivate"
import "../react/pages/users/edit"
import "../react/pages/reset_passwords/index"
import "../react/pages/reset_passwords/edit"

require("trix")
require("@rails/actiontext")
require.context('../images/', true)
require.context('../sounds/', true)
Rails.start()
global.Rails = Rails
ActiveStorage.start()
