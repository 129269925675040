import React, { FC } from "react";
import { GradeImg } from "./gradeImg";
import { GradeCount } from "../../types/gradeCount";
import { DivFlexCenCen } from "../utility/flex";
import { PTextSecondaryM } from "../utility/textSecondary";
import { PTextPrimaryM } from "../utility/textPrimary";

type Props = {
  gradeCounts: GradeCount[]|null;
  questionSetId: number;
  remSize: number;
}

export const GradeImgAndCounts:FC<Props> = (props: Props) => {
  const { gradeCounts, questionSetId, remSize } = props;

  const sGradeImageAndCount = () => {
    if(gradeCounts.length === 0) return;

    const gradeCount: GradeCount = gradeCounts.find(g => g.questionSetId === questionSetId);
    if(gradeCount == null) return;

    if(gradeCount.sCount > 0) {
      return(<><DivFlexCenCen><GradeImg grade={60} remSize={remSize}/><PTextSecondaryM>{ gradeCount.sCount }</PTextSecondaryM></DivFlexCenCen></>)
    }
  }

  const aGradeImageAndCount = () => {
    if(gradeCounts.length === 0) return;

    const gradeCount: GradeCount = gradeCounts.find(g => g.questionSetId === questionSetId);
    if(gradeCount == null) return;

    if(gradeCount.aCount > 0) {
      return(<><DivFlexCenCen><GradeImg grade={50} remSize={remSize}/><PTextSecondaryM>{ gradeCount.aCount }</PTextSecondaryM></DivFlexCenCen></>)
    }
  }

  const bGradeImageAndCount = () => {
    if(gradeCounts.length === 0) return;

    const gradeCount: GradeCount = gradeCounts.find(g => g.questionSetId === questionSetId);
    if(gradeCount == null) return;

    if(gradeCount.bCount > 0) {
      return(<><DivFlexCenCen><GradeImg grade={40} remSize={remSize}/><PTextSecondaryM>{ gradeCount.bCount }</PTextSecondaryM></DivFlexCenCen></>)
    }
  }



  return(
    <>
      <DivFlexCenCen>
        { sGradeImageAndCount() }
        { aGradeImageAndCount() }
        { bGradeImageAndCount() }
      </DivFlexCenCen>
    </>
  )
}
