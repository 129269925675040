import React, { FC } from "react";
import styled from 'styled-components';
import { CORRECT_PINK, GREEN, INCORRECT_BLUE, ORANGE_PRIMARY, ORANGE_SECONDARY, TEXT_PRIMARY, TEXT_SECONDARY } from "../../config/Constants";
import { Difficulty } from "../../types/difficulty";
type Props = {
  difficulty: Difficulty,
  remSize: number,
}

export const DifficultyLabel:FC<Props> = (props: Props) => {
  const { difficulty, remSize } = props;

  const difficultyString: (difficulty: Difficulty) => string = (difficulty: Difficulty) => {
    if (difficulty === 'basic') {
      return 'やさしい'
    } else if (difficulty === 'normal') {
      return 'ふつう'
    } else if (difficulty === 'hard') {
      return 'むずかしい'
    } else if (difficulty === 'veryHard') {
      return '激ムズ'
    } else {
      ''
    }
  }

  const labelStyle: (difficulty: Difficulty) => CSSProperties = (difficulty: Difficulty) => {
    if (difficulty === 'basic') {
      return {color: GREEN, border: `1px solid ${GREEN}`}
    } else if (difficulty === 'normal') {
      return {color: ORANGE_SECONDARY, border: `1px solid ${ORANGE_SECONDARY}`}
    } else if (difficulty === 'hard') {
      return {color: ORANGE_PRIMARY, border: `1px solid ${ORANGE_PRIMARY}`}
    } else if (difficulty === 'veryHard') {
      return {color: CORRECT_PINK, border: `1px solid ${CORRECT_PINK}`}
    } else {
      ''
    }
  }


  return(
    <>
      <SpanLabel style={{...labelStyle(difficulty), fontSize: `${remSize}rem`}}>{difficultyString(difficulty)}</SpanLabel>
    </>
  )
}

const SpanLabel = styled.span`
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
`
