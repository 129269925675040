import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import { PageTitle } from '../../../components/atoms/pageTitle';
import { DivFlexCenCen, DivFlexSparoCen, DivFlexSpbetEnd, DivFlexEndEnd, DivFlexEnd, DivFlexCen } from "../../../components/utility/flex";
import { BORDER_SECONDARY, RED } from "../../../config/Constants";
import { PTextSecondaryM, PTextSecondaryXS } from "../../../components/utility/textSecondary";
import { QuestionSet } from "../../../types/questionSet";
import { axios } from '../../../config/Axios';
import { useContext } from "react";
import { CurrentUserContext, CurrentUser } from "../../../providers/currentUser";
import { BestTimeAndGrade } from '../../../types/bestTimeAndGrade';
import { GradeImg } from '../../../components/atoms/gradeImg';
import { PTextSecondaryS } from "../../../components/utility/textSecondary";
import { SecToMin } from "../../../components/atoms/secToMin";
import { Score } from "../../../components/atoms/score";
import { Question } from '../../../types/question';
import { QuestionImage } from '../../../components/modules/questionImage';
import { DivPaddingSide0p5rem, DivWrap } from '../../../components/utility/wrap';
import { LoaderInnerBallPulse } from '../../../components/atoms/loaderInnerBallPulse';
import { HrSecondary } from '../../../components/utility/hr';
import { ALink } from "../../../components/utility/link";
import { ChallengeButton } from './challengeButton';
import { useAnswerSetHistory } from '../../../hooks/useAnswerSetHistory';
import { AnswerSetHistoryWithBar } from '../../../components/atoms/answerSetHistoryWithBar';
import { SpanLink } from '../../../components/utility/link';
import { Contest } from "../../../types/contest";
import { ContestLabel } from "../../../components/atoms/contestLabel";
import { ContestTerm } from "../../../components/atoms/contestTerm";
import { PracticeButton } from "./practiceButton";

type Props = {
  questionSet: QuestionSet;
  bestTimeAndGrade: BestTimeAndGrade;
  contests: Contest[];
  notLoginAndDone: boolean;
  keisanClubNotionUrl: string;
}

export const QuestionSetShow:FC<Props> = (props:Props) => {
  const { questionSet, bestTimeAndGrade, contests, notLoginAndDone, keisanClubNotionUrl } = props;
  const currentUser:CurrentUser = useContext(CurrentUserContext)
  const { answerSets, getAnswerSetHistory, isMoreAnswerSetHistory } = useAnswerSetHistory({initMaxItemsPerPage: 3, questionSetId: questionSet.id});
  const [question, setQuestion] = useState<Question|null>(null);

  useEffect(() => {
    axios.get(`/api/question_sets/${questionSet.id}/example`)
      .then(response => {
        setQuestion(response.data.question);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
      });
  }, [])

  return(
    <>
      <PageTitle text={questionSet.title} backWord='単元学習' backLinkPath='/question_sets'/>
      <DivPaddingSide0p5rem>
        <DivFlexCenCen style={{marginTop: '2rem'}}>
          <PTextSecondaryS>自己ベスト記録</PTextSecondaryS>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginBottom: '1rem'}}>
          <DivFlexSpbetEnd style={{marginTop: '0.5rem'}}>
            {
              bestTimeAndGrade.score != null
              ? <DivFlexCenCen>
                <div style={{margin: '0 1rem 0 0'}}>
                  <Score score={bestTimeAndGrade.score} remSize={1.5}/>
                </div>
                <div style={{margin: '0 1rem 0 0'}}>
                  <SecToMin sec={bestTimeAndGrade.time} remSize={1.5}/>
                </div>
                <GradeImg grade={bestTimeAndGrade.grade} remSize={2}/>
              </DivFlexCenCen>
              : <PTextSecondaryM>未チャレンジ</PTextSecondaryM>
            }
          </DivFlexSpbetEnd>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginTop: '2rem'}}>
          <PTextSecondaryS>判定基準 (10問)</PTextSecondaryS>
        </DivFlexCenCen>
        <DivFlexCenCen>
          <DivStandardBoard>
            <DivFlexSpbetEnd style={{marginBottom: '0.25rem'}}>
              <PTextSecondaryM>S</PTextSecondaryM>
              <DivFlexEndEnd>
                <SecToMin sec={questionSet.sGradeTime} remSize={1.25}/>
                <PTextSecondaryM style={{marginLeft: '0.5rem'}}>100点</PTextSecondaryM>
              </DivFlexEndEnd>
            </DivFlexSpbetEnd>
            <DivFlexSpbetEnd style={{marginBottom: '0.25rem'}}>
              <PTextSecondaryM>A</PTextSecondaryM>
              <DivFlexEndEnd>
                <SecToMin sec={questionSet.aGradeTime} remSize={1.25}/>
                <PTextSecondaryM style={{marginLeft: '0.5rem'}}>100点</PTextSecondaryM>
              </DivFlexEndEnd>
            </DivFlexSpbetEnd>
            <DivFlexSpbetEnd style={{marginBottom: '0.25rem'}}>
              <PTextSecondaryM>B</PTextSecondaryM>
              <DivFlexEndEnd>
                <PTextSecondaryM style={{marginLeft: '0.5rem'}}>100点</PTextSecondaryM>
              </DivFlexEndEnd>
            </DivFlexSpbetEnd>
            <DivFlexSpbetEnd style={{marginBottom: '0.25rem'}}>
              <PTextSecondaryM>C</PTextSecondaryM>
              <DivFlexEndEnd>
                <PTextSecondaryM style={{marginLeft: '0.5rem'}}>80点</PTextSecondaryM>
              </DivFlexEndEnd>
            </DivFlexSpbetEnd>
            <DivFlexSpbetEnd style={{marginBottom: '0.25rem'}}>
              <PTextSecondaryM>D</PTextSecondaryM>
              <DivFlexEndEnd>
                <PTextSecondaryM style={{marginLeft: '0.5rem'}}>60点</PTextSecondaryM>
              </DivFlexEndEnd>
            </DivFlexSpbetEnd>
          </DivStandardBoard>
        </DivFlexCenCen>
        <div style={{padding: '1rem', width: '80%', margin: '0 auto'}}>
            {
              answerSets.length > 0 
              && <DivFlexSpbetEnd style={{marginBottom: '1rem', borderBottom: `${BORDER_SECONDARY} solid  1px`, padding: '0.25rem 0.5rem'}}>
                <PTextSecondaryXS>チャレンジ履歴</PTextSecondaryXS>
              </DivFlexSpbetEnd>
            }
          {
            answerSets.map((answerSet, index) => (
              <div key={index}>
                <AnswerSetHistoryWithBar resultAnswerSet={answerSet}/>
              </div>
            ))
          }
          {
            isMoreAnswerSetHistory()
            ? <DivFlexEnd><SpanLink onClick={getAnswerSetHistory} style={{cursor: 'pointer', fontSize: '0.75rem'}}>もっとみる</SpanLink></DivFlexEnd>
            : null
          }
        </div>
        <DivFlexCen style={{marginTop: '2rem'}}>
          <DivNoteWrap>
            <PTextSecondaryXS>
              えんぴつ、メモなど、問題を解く準備ができたら、チャレンジしましょう!
            </PTextSecondaryXS>
          </DivNoteWrap>
        </DivFlexCen>
        <DivFlexCenCen>
          <ContestLabel contests={contests} questionSetId={questionSet.id} showRankingLink={true} />
        </DivFlexCenCen>
        <DivFlexCenCen>
          <ContestTerm contests={contests} questionSetId={questionSet.id}/>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginBottom: '1rem'}}>
          <ChallengeButton questionSet={questionSet} currentUser={currentUser} notLoginAndDone={notLoginAndDone } keisanClubNotionUrl={keisanClubNotionUrl}/>
        </DivFlexCenCen>
        <PracticeButton questionSet={questionSet} currentUser=
        {currentUser} />
        {
          currentUser.isActivated
          ? null
          : <DivWrap>
            <DivFlexCenCen style={{marginTop: '0.5rem'}}>
            </DivFlexCenCen>
            <DivFlexCenCen style={{marginTop: '0.5rem'}}>
              <ALink href={'/users/new'}>会員登録(無料)</ALink>
            </DivFlexCenCen>
            <DivFlexCenCen style={{marginTop: '1rem'}}>
              <PTextSecondaryXS>すでに会員の方は、こちら</PTextSecondaryXS>
            </DivFlexCenCen>
            <DivFlexCenCen style={{marginTop: '0.5rem'}}>
              <ALink href={'/login'}>ログイン</ALink>
            </DivFlexCenCen>
          </DivWrap>
        }

        <DivFlexCen style={{marginTop: '0.25rem'}}>
        </DivFlexCen>
        <DivQuestionWrap>
          <div style={{marginTop: '3rem'}}>
            <PTextSecondaryM>問題例</PTextSecondaryM>
            <HrSecondary/>
            {
              question != null
              ? <QuestionImage text={question.text} imageUrl={question.imageUrl} />
              : <LoaderInnerBallPulse remSize={1.5} />
            }
          </div>
        </DivQuestionWrap>
      </DivPaddingSide0p5rem>
    </>
  )
}

const DivButtonWrap = styled.div`
  width: 12rem;
`;

const DivQuestionWrap = styled.div`
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DivStandardBoard = styled.div`
  min-width: 8.5rem;
  padding: 1rem 1.5rem;
  border: 1px solid ${BORDER_SECONDARY};
  border-radius: 0.5rem;
`;

const DivNoteWrap = styled.div`
  width: 15rem;
  padding: 1rem;
`

const SpanContest = styled.div`
  font-weight: bold;
  color: ${RED};
  font-size: 1rem;
  text-align: center;
`;
