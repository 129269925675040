import React, { FC } from "react";
import styled from 'styled-components';
import { TEXT_PRIMARY, TEXT_SECONDARY } from "../../config/Constants";
type Props = {
  sec: number,
  remSize: number,
}

export const SecToMin:FC<Props> = (props: Props) => {
  const { sec, remSize } = props;

  const min_num = Math.floor(sec / 60)
  const sec_num = sec % 60

  const sec_string = sec_num >= 10 ? `${sec_num}` : `0${sec_num}`

  return(
    <>
      <DivWrap>
        {
          min_num > 0
          ? <>
            <SpanNum style={{fontSize: `${remSize}rem`}}>{min_num}</SpanNum>
            <Span style={{fontSize: `${remSize/2}rem`, marginRight: '0.25rem'}}>分</Span>
          </>
          : null
        }
        <SpanNum style={{fontSize: `${remSize}rem`}}>{sec_string}</SpanNum>
        <Span style={{fontSize: `${remSize/2}rem`}}>秒</Span>
      </DivWrap>
    </>
  )
}

const DivWrap = styled.div`

`

const SpanNum = styled.span`
  vertical-align: bottom;
  line-height: auto;
  color: ${TEXT_PRIMARY};
`

const Span = styled.span`
  vertical-align: bottom;
  line-height: auto;
  color: ${TEXT_SECONDARY};
`
