import React from "react";
import styled from "styled-components";
import { DivFlexCenCen } from "../utility/flex";

type Props = {
  text: string;
  imageUrl: string|null;
  questionNum: number|null;
}

export const QuestionImage:FC<Props>= (props) => {
  const { text, imageUrl, questionNum } = props;

  if (text.length > 0) {
    return(
      <>
        {
          imageUrl == null
          ? <DivQuestionWrap>
            <DivTextWrapNoImage>
              <DivTextWrapNoImageInner>
                {
                  questionNum != null
                  ? <DivQuestionNum style={{fontSize: '1.5rem'}}>({questionNum})</DivQuestionNum>
                  : null
                }
                <DivText style={{fontSize: '1.5rem'}}><div dangerouslySetInnerHTML={{__html: text}}></div></DivText>
              </DivTextWrapNoImageInner>
            </DivTextWrapNoImage>
          </DivQuestionWrap>
          : <DivQuestionWrap>
            <DivImageWrap>
              <ImgQuestion src={imageUrl} />
            </DivImageWrap>
            <DivTextWrap>
              {
                questionNum != null
                ? <DivQuestionNum>({questionNum})</DivQuestionNum>
                : null
              }
              <DivText><div dangerouslySetInnerHTML={{__html: text}}></div></DivText>
            </DivTextWrap>
          </DivQuestionWrap>
        }
      </>
    )
  } else if (imageUrl != null){
    return(
      <>
        <DivQuestionWrapWithoutText>
          {
            questionNum != null
            ? <DivQuestionNum>({questionNum})</DivQuestionNum>
            : null
          }
          <DivImageWrap>
            <ImgQuestionWithoutText src={imageUrl} />
          </DivImageWrap>
        </DivQuestionWrapWithoutText>
      </>
    )
  }
}
const DivQuestionWrap = styled.div`
  margin: 0 auto;
  max-width: 30rem;
  background-color: #fff;
  padding: 0.5rem 0;
`;

const DivQuestionWrapWithoutText = styled.div`
  margin: 0 auto;
  max-width: 30rem;
  background-color: #fff;
  padding: 0.5rem 0;
  min-height: 11.5rem;
  display: flex;
  justify-content: center;
`;

const DivQuestionNum = styled.div`
  white-space: nowrap;
`;

const DivImageWrap = styled.div`
  display: flex;
  align-items: end;
`

const ImgQuestion = styled.img`
  max-width: 15rem;
  height: 7.5rem;
  object-fit: scale-down;
  vertical-align: end;
  display: block;
  margin: auto auto 0 auto;
`;

const ImgQuestionWithoutText = styled.img`
  height: 14rem;
  max-width: 20rem;
  object-fit: scale-down;
  vertical-align: end;
  display: block;
  margin: auto auto 0 auto;
`;

const DivText = styled.div`
  padding-left: 0.5rem;
`;

const DivTextWrap = styled.div`
  min-height: 4rem;
  padding: 0.5rem;
  display: flex;
`;

const DivTextWrapNoImage = styled.div`
  min-height: 11.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

const DivTextWrapNoImageInner = styled.div`
  display: flex;
`;
