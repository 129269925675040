import styled from "styled-components";
import { TEXT_PRIMARY } from "../../config/Constants";


export const PTextPrimaryM = styled.p`
  color: ${TEXT_PRIMARY};
  font-size: 1rem;
`

export const PTextPrimaryS = styled(PTextPrimaryM)`
  font-size: 0.875rem;
`

export const PTextPrimaryXS = styled(PTextPrimaryM)`
  font-size: 0.75rem;
`

export const PTextPrimaryL = styled(PTextPrimaryM)`
  font-size: 1.125rem;
`

export const PTextPrimaryXL = styled(PTextPrimaryM)`
  font-size: 1.25rem;
`

export const PTextPrimaryXXL = styled(PTextPrimaryM)`
  font-size: 1.375rem;
`

export const PTextPrimaryXXXL = styled(PTextPrimaryM)`
  font-size: 1.5rem;
`
export const PTextPrimaryCenXS = styled(PTextPrimaryXS)`
  text-align: center;
`
export const PTextPrimaryCenS = styled(PTextPrimaryS)`
  text-align: center;
`
export const PTextPrimaryCenM = styled(PTextPrimaryM)`
  text-align: center;
`
export const PTextPrimaryCenL = styled(PTextPrimaryL)`
  text-align: center;
`
export const PTextPrimaryCenXL = styled(PTextPrimaryXL)`
  text-align: center;
`
export const PTextPrimaryCenXXL = styled(PTextPrimaryXXL)`
  text-align: center;
`
export const SpanTextPrimaryS = styled.span`
  color: ${TEXT_PRIMARY};
  font-size: 0.875rem;
`

export const SpanTextPrimaryM = styled.span`
  color: ${TEXT_PRIMARY};
  font-size: 1rem;
`

export const SpanTextPrimaryL = styled.span`
  color: ${TEXT_PRIMARY};
  font-size: 1.125rem;
`

export const SpanTextPrimaryXL = styled.span`
  color: ${TEXT_PRIMARY};
  font-size: 1.25rem;
`


