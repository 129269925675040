import React, { FC } from "react";
import styled from 'styled-components';
import { Contest } from "../../types/contest";
import { RED, TEXT_SECONDARY } from '../../config/Constants';
import { DivWrap } from "../utility/wrap";
import { ALink } from "../utility/link";
import { DivFlexCenCen } from "../utility/flex";

type Props = {
  contests: Contest[],
  questionSetId: number,
  showRankingLink?: boolean,
}

export const ContestLabel:FC<Props> = (props: Props) => {
  const { contests, questionSetId, showRankingLink=false } = props;

  const contest:Contest = contests.find(c => c.questionSetId === questionSetId);
  if(contest == null) return null;

  return(
    <>
      <DivWrap>
        <PContest>コンテスト開催中！</PContest>
        {
          showRankingLink
          ? <DivFlexCenCen style={{marginBottm: '1rem'}}><ALink href={`/contests/${contest.id}`}>ランキング</ALink></DivFlexCenCen>
          : null
        }
      </DivWrap>
    </>
  )
}

const PContest = styled.div`
  font-weight: bold;
  color: ${RED};
  font-size: 1rem;
`;
