import React, { FC } from "react";
import { useState } from "react";
import styled, { keyframes } from 'styled-components';
import { axios } from "../../config/Axios";
import { ALink } from "../utility/link";

export const OpinionLinkExternal:FC = () => {

  return(
    <>
      <ALink href={'/opinions/new'} target='_blank' style={{textDecoration: 'underline'}}>ご意見・お問い合わせ<i className="fas fa-external-link-alt"/></ALink>
    </>
  )
}
