import React, { FC } from "react";
import { useState } from "react";
import styled, { keyframes } from 'styled-components';
import { axios } from "../../config/Axios";
import { ALink } from "../utility/link";
import { PTextPrimaryM } from "../utility/textPrimary";


type Props = {
  questionId: number,
}

export const WrongAnswerReport:FC<Props> = (props: Props) => {
  const { questionId } = props;
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)


  const submitWrongAnswerReport = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    axios.post('/api/opinions/wrong_answer_report', {
      questionId: questionId
    })
      .then(response => {
      })
      .catch(error => {
        console.log(error);
      });
  }

  return(
    <>
    {
      isSubmitted
      ? <PTextPrimaryM>ご報告ありがとうございます。</PTextPrimaryM>
      : <ALink href={'#'} onClick={submitWrongAnswerReport}>解答の間違いの報告</ALink>
    }
    </>
  )
}
