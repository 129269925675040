import styled from "styled-components";

export const DivOverallWrap = styled.div`
  margin: 0 auto;
  max-width: 30rem;
  padding-bottom: 2rem;
`
export const DivOverallWrapWide = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  padding-bottom: 2rem;
`

export const  DivPaddingSide0p5rem = styled.div`
  padding: 0 0.5rem;
`

export const  DivPaddingSide1rem = styled.div`
  padding: 0 1rem;
`

export const DivWrap = styled.div`
`
