import { axios } from "../config/Axios";

export const createAnswerSet: (questionSetId: number) => void = (questionSetId) => {
  axios.post(`/api/answer_sets`,{
    questionSetId: questionSetId
  })
  .then(response => {
    window.location.href = '/drills'
  })
  .catch(error => {
    console.log(error);
  })
}

export const createPracticeAnswerSet: (questionSetId: number) => void = (questionSetId) => {
  axios.post(`/api/practice_answer_sets`,{
    questionSetId: questionSetId
  })
  .then(response => {
    window.location.href = '/practice_drills'
  })
  .catch(error => {
    console.log(error);
  })
}

export const createCalcAnswerSet: (questionSetId: number) => void = (questionSetId) => {
  axios.post(`/api/calc_answer_sets`,{
    questionSetId: questionSetId,
    questionCount: 25,
  })
  .then(response => {
    window.location.href = `/drills/${response.data.id}/calc`
  })
  .catch(error => {
    console.log(error);
  })
}
