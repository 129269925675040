import React, { FC } from "react";
import styled from 'styled-components';
import { CORRECT_PINK } from '../../config/Constants';
type Props = {
  remSize: number,
  showText?: boolean
}

export const CorrectCircle:FC<Props> = (props: Props) => {
  const { remSize, showText = true } = props;

  const circleRemSizeCss = (remSize:number) => {
    return {width: `${remSize}rem`, height: `${remSize}rem`, border: `${remSize/4}rem solid ${CORRECT_PINK}`}
  }

  const textRemSizeCss = (remSize:number) => {
    return {fontSize: `${remSize}rem`,  }
  }

  const wrapRemSize = (remSize:number) => {
    return {height: `${remSize}rem`, lineHeight: `${remSize}rem`  }
  }

  return(
    <DivWrap style={wrapRemSize(remSize)}>
      <DivCircle style={circleRemSizeCss(remSize)}/>
      {
        showText && <SpanText style={textRemSizeCss(remSize)}>
          正解
        </SpanText>
      }
    </DivWrap>
  )
}

const DivWrap = styled.div`
  display: flex;
  align-items: center;
`;


const DivCircle = styled.div`
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const SpanText = styled.span`
  color: #E66B58;
`
