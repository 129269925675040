import React, { FC } from "react";
import styled, { keyframes } from 'styled-components';
import { CurrentUser } from '../../../providers/currentUser';
import { DivPrimaryOrangeButton, DivSecondaryOrangeButtonDisable } from '../../../components/utility/buttons';
import { createAnswerSet } from '../../../actions/actions';
import { DivFlexCenCen } from "../../../components/utility/flex";
import { PTextSecondaryCenXS } from "../../../components/utility/textSecondary";
import { ALink } from "../../../components/utility/link";
import { QuestionSet } from "../../../types/questionSet";

type Props = {
  questionSet: QuestionSet;
  currentUser: CurrentUser;
  notLoginAndDone: boolean;
  keisanClubNotionUrl: string;
}

export const ChallengeButton:FC<Props> = (props: Props) => {
  const { questionSet, currentUser, notLoginAndDone, keisanClubNotionUrl } = props;

  const isDrillSemiMember = () =>{
    return (currentUser.isDrillSemiMember || currentUser.isPremiumPlan)
  }


  // 非ログインユーザー
  if(!currentUser.isActivated) {
    return(
      <>
      {
        notLoginAndDone
        ? <DivButtonWrap>
          <DivSecondaryOrangeButtonDisable>チャレンジする</DivSecondaryOrangeButtonDisable>
          <DivFlexCenCen>
            <PTextSecondaryCenXS>
              ログインすると続けてチャレンジできます。
            </PTextSecondaryCenXS>
          </DivFlexCenCen>
        </DivButtonWrap>
        : <DivButtonWrap>
          <DivPrimaryOrangeButton onClick={() => createAnswerSet(questionSet.id)}>チャレンジする</DivPrimaryOrangeButton>
        </DivButtonWrap>
      }
      </>
    )
  }

  // 以下、ユーザーはログイン済み
  return(
    <>
      {
        currentUser.isChallengeable
        ? <DivButtonWrap>
          <DivPrimaryOrangeButton onClick={() => createAnswerSet(questionSet.id)}>チャレンジする</DivPrimaryOrangeButton>
          <DivFlexCenCen>
            {
              currentUser.remainChallengeCount <= 3
              ? <PTextSecondaryCenXS style={{marginTop: '0.25rem'}}>本日残り{currentUser.remainChallengeCount}回</PTextSecondaryCenXS>
              : null
            }
          </DivFlexCenCen>
        </DivButtonWrap>
        : <DivButtonWrap>
          <DivSecondaryOrangeButtonDisable>チャレンジする</DivSecondaryOrangeButtonDisable>
          <DivFlexCenCen>
            <PTextSecondaryCenXS>
              無料プランは1日3チャレンジまで<br/>
            </PTextSecondaryCenXS>
          </DivFlexCenCen>
        </DivButtonWrap>
      }
    </>
  )
}

const DivButtonWrap = styled.div`
  width: 12rem;
`;
