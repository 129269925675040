
import React, { FC } from "react";
import styled, { keyframes } from 'styled-components';
import { RESPONSIVE_SIZE_SP, WHITE, BORDER } from "../../config/Constants";

type Props = {
  children?: React.ReactNode;
  closeAction?: () => void;
  isModalShow?: boolean;
};

export const Modal:FC<Props> = (props) => {
  const { children, closeAction, isModalShow } = props;

  return(
    <>
    {
      isModalShow == null || isModalShow
      ? <DivModal style={{width: '100%', height: `${window.innerHeight}px`}}>
        <DivModalOutSide onClick={closeAction}/>
        <DivModalInner>
          { closeAction == null
            ? null
            : <DivModalClose onClick={closeAction}>とじる<i className="far fa-times-circle"></i></DivModalClose>
          }
          { children }
        </DivModalInner>
      </DivModal>
      : null
    }
    </>
    )
}

const DivModalOutSide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.1;
`;

const DivModalClose = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
  pointer-events: auto;
  transition: 0.5s opacity;
  display: flex;
  align-items: center;
`;


const DivModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  transition: 0.5s opacity;
`;

const DivModalInner = styled.div`
  position: relative;
  padding: 3.2rem;
  background-color: ${WHITE};
  border: 1px solid ${BORDER};
  border-radius: 0.5rem;

  @media (max-width: ${RESPONSIVE_SIZE_SP}px) {
    padding: 3.2rem 1.6rem;
    margin: 0 1.6rem;
  }
`;
