import React, { FC } from "react";
import { Contest } from "../../types/contest";
import { APrimaryOrangeButton } from "../utility/buttons";

type Props = {
  contest: Contest|null,
}

export const ContestRankingLink:FC<Props> = (props: Props) => {
  const { contest } = props;

  if(contest == null) return null;

  return(
    <>
      <APrimaryOrangeButton href={`/contests/${contest.id}`}>ランキングはこちら</APrimaryOrangeButton>
    </>
  )
}
