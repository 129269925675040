import React from "react";
import styled from "styled-components";
import { ALink } from "../utility/link";
import { PTextPrimaryM, PTextPrimaryS } from "../utility/textPrimary";

export const LegalConfirm:FC= () => {
  return(
    <>
      <PTextPrimaryS>
        会員登録(無料)をすることにより、
        <ALink href={'/legal/terms'} target='_blank'>利用規約<i className="fas fa-external-link-alt"/></ALink>
        と
        <ALink href={'/legal/privacy_policy'} target='_blank'>プライバシーポリシー<i className="fas fa-external-link-alt"/></ALink>
        に同意したものとします。
      </PTextPrimaryS>
    </>
  )
}
