import React, { FC, useState, useEffect } from "react";
import styled from 'styled-components';
import { axios } from '../../../config/Axios';
import { ResultAnswer } from '../../../types/resultAnswer';
import { AnswerStringToDisplay } from '../../../components/atoms/answerStringToDisplay';
import { ResultAnswerSet } from '../../../types/resultAnswerSet';
import { LoaderInnerBallPulse } from '../../../components/atoms/loaderInnerBallPulse';
import { CorrectCircle } from '../../../components/atoms/correctCircle';
import { InCorrectCrossMark } from '../../../components/atoms/inCorrectCrossMark';
import { Modal } from "../../../components/atoms/modal";
import { QuestionImage } from "../../../components/modules/questionImage";
import { DivPrimaryOrangeButton } from "../../../components/utility/buttons";
import { LINK_BULE } from "../../../config/Constants";
import { DivFlexCenCen, DivFlexCen } from "../../../components/utility/flex";
import { DivOverallWrap, DivWrap } from "../../../components/utility/wrap";
import { PTextSecondaryM } from "../../../components/utility/textSecondary";
import { GradeImg } from "../../../components/atoms/gradeImg";
import { WrongAnswerReport } from "../../../components/atoms/wrongAnswerReport";
import { Contest } from "../../../types/contest";
import { ContestRankingLink } from "../../../components/atoms/contestRankingLink";


type Props = {
  answerSetId: number
  contest: Contest|null;
}

const initialResultAnswerSet:ResultAnswerSet = {
  isSubmitter: false,
  score: null,
  timeSec: null,
  rank: null,
  questionSetId: null,
  grade: null
}


export const AnswerSetResult:FC<Props> = (props) => {
  const { answerSetId, contest } = props;
  const [answers, setAnswers] = useState<ResultAnswer[]>([]);
  const [resultAnswerSet, setResultAnswerSet] = useState<ResultAnswerSet>(initialResultAnswerSet);
  const [resultModalShow, setResultModalShow] = useState<boolean>(false);
  const [userName, setUserName] = useState<string|null>(null);

  useEffect(() => {
    axios.get(`/api/answer_sets/${answerSetId}`)
      .then(response => {
        setAnswers(response.data.answers);
        setResultAnswerSet(response.data.resultAnswerSet);
        setUserName(response.data.userName);
      })
      .catch(error => {
      })
      .finally(() => {
      });
  }, [])

  useEffect(() => {
    setTimeout(() => loading(answers), 360)
  }, [answers])

  const loading = (answers: resultAnswer[]) => {
    const loadingAnswerIndex = answers.findIndex(answer => answer.isLoading === true)
    if(loadingAnswerIndex >= 0) {
      const newAnswers = [...answers]
      newAnswers[loadingAnswerIndex].isLoading = false;
      setAnswers(newAnswers);
    }
  };

  const isloadingComplete = (answers) => {
    return (answers.length > 0 && answers.every((answer) => answer.isLoading === false))
  };

  const judgeResult = (answer: ResultAnswer) => {
    if(answer.isLoading){
      return <LoaderInnerBallPulse remSize={0.75}/>
    } else if(answer.isCorrect) {
      return <CorrectCircle remSize={1}/>
    } else {
      return <InCorrectCrossMark remSize={1}/>
    }
  }

  const userNameSubmitOrUserName = () => {
    if(userName == null){
      return(
        <>
        {
          resultAnswerSet.isSubmitter
          ? <>
              <DivFlexCen>お疲れ様でした！</DivFlexCen>
              <DivScoreAndTimeWrap>
                <PResult>得点:{resultAnswerSet.score}点</PResult>
                <PResult>タイム: {resultAnswerSet.timeSec}秒</PResult>
              </DivScoreAndTimeWrap>
              <PTextSecondaryM>グレード</PTextSecondaryM>
              <GradeImg grade={resultAnswerSet.grade} remSize={2}/>
              <DivFlexCen>でした！</DivFlexCen>
            </>
          : null
        }
        </>
      )
    } else {
      return(
        <>
          <DivFlexCen>{userName}さん</DivFlexCen>
          <DivFlexCen>お疲れ様でした！</DivFlexCen>
          <DivScoreAndTimeWrap>
            <PResult>得点:{resultAnswerSet.score}点</PResult>
            <PResult>タイム: {resultAnswerSet.timeSec}秒</PResult>
            <PTextSecondaryM>グレード</PTextSecondaryM>
            <GradeImg grade={resultAnswerSet.grade} remSize={2}/>
          </DivScoreAndTimeWrap>
          <DivFlexCen>でした！</DivFlexCen>
          <ContestRankingLink contest={contest}/>
        </>
      )
    }
  }

  const questionModalClose:(number) => void = (index:number) => {
    const newAnswers = [...answers]
    newAnswers[index].question.isModalShow = false
    newAnswers[index].question.correctAnswerShow = false
    setAnswers(newAnswers);
  }

  const questionModalOpen:(number) => void = (index:number) => {
    const newAnswers = [...answers]
    newAnswers[index].question.isModalShow = true
    setAnswers(newAnswers);
  }

  const correctAnswerOpen:(number) => void = (index:number) => {
    const newAnswers = [...answers]
    newAnswers[index].question.correctAnswerShow = true
    setAnswers(newAnswers);
  }


  return(
    <DivOverallWrap>
      <DivResultWrap>
        <TableResultWrap>
          <Thead>
            <Tr>
              <Th>番号</Th>
              <Th>あなたの回答</Th>
              <Th>正誤</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              answers.map((answer, index) =>
                ( <Tr key={index}>
                    <Td>
                      <DivQuestionNum> {index + 1} </DivQuestionNum>
                    </Td>
                    <TdAnswer>
                      <AnswerStringToDisplay
                          answer={answer.answer}
                          isInputing={false}/>
                    </TdAnswer>
                    <TdJudge>{judgeResult(answer)}</TdJudge>
                    <Td>
                      <AShowQuestionLink key={index} href={'#'} onClick={() => questionModalOpen(index)}>問題・解答</AShowQuestionLink>
                    </Td>
                  </Tr>
                )
              )
            }
          </Tbody>
        </TableResultWrap>
      </DivResultWrap>
      {
        isloadingComplete(answers)
        ? <DivButtonWrap>
            <DivResultButton onClick={()=>setResultModalShow(true)}>結果を見る</DivResultButton>
        </DivButtonWrap>
        : null
      }
      {
        resultModalShow
        ? <Modal closeAction={() => setResultModalShow(!resultModalShow)}>
          <DivTextAlignCenter>
            {
              userNameSubmitOrUserName()
            }
            <DivATopLinkWrap>
              <AToTopLink href={`/question_sets/${resultAnswerSet.questionSetId}`}>問題へもどる</AToTopLink>
            </DivATopLinkWrap>
          </DivTextAlignCenter>
        </Modal>
        : null
      }
      {
        answers.map((answer, index) => (
          <div key={index}>
            {
              answer.question.isModalShow
              ? <Modal closeAction={() => questionModalClose(index)}>
                <DivQuestionImageWrap>
                  <QuestionImage text={answer.question.text} questionNum={index+1} imageUrl={answer.question.imageUrl}></QuestionImage>
                </DivQuestionImageWrap>
                <DivWrap style={{marginBottom: '1rem'}}>
                  <PTextSecondaryM>あなたの回答</PTextSecondaryM>
                  <AnswerStringToDisplay answer={answer.answer} isInputing={false}/>
                </DivWrap>
                <PTextSecondaryM>解答</PTextSecondaryM>
                <DivAnswerWrap>
                  {
                    answer.question.correctAnswerShow
                    ? <AnswerStringToDisplay
                    answer={answer.question.correctAnswer}
                    isInputing={false}/>
                    : <DivShowAnswerButton onClick={() => correctAnswerOpen(index)}>答えを見る</DivShowAnswerButton>
                  }
                </DivAnswerWrap>
                <DivFlexCenCen style={{marginTop: '2rem'}}>
                  <WrongAnswerReport questionId={answer.question.id}></WrongAnswerReport>
                </DivFlexCenCen>
              </Modal>
              : null
            }
          </div>
        ))
      }
    </DivOverallWrap>
  )
}

const DivScoreAndTimeWrap = styled.div`
  margin: 1rem auto;
`

const DivAnswerWrap = styled(DivFlexCenCen)`
  padding: 1rem;
  height: 5rem;
`;

const DivShowAnswerButton = styled(DivPrimaryOrangeButton)`
  margin-top: 2rem;
  height: 3rem;
  max-height: 3rem;
`;

const DivResultButton = styled(DivPrimaryOrangeButton)`
  height: 3.5rem;
  max-height: 3.5rem;
`;

const DivButtonWrap = styled.div`
  width: 10rem;
  margin: 2rem auto;
`;

const DivQuestionImageWrap = styled.div`
  max-width: 30rem;
  width: 80vw;
`

const AShowQuestionLink = styled.a`
  text-decoration: none;
  color: ${LINK_BULE};
`;

const DivATopLinkWrap = styled.div`
  margin-top: 3rem;
`

const AToTopLink = styled(AShowQuestionLink)`
  margin-top: 2rem;
`

const PResult = styled.p`
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: bold;
`;

const DivTextAlignCenter = styled.div`
  padding: 1rem;
  text-align: center;
`;

const TableResultWrap = styled.table`
`;

const Thead = styled.thead`
`;

const Th = styled.th`
  padding: 0.25rem 1rem;
  text-align: center;
  vertical-align: middle;
`;

const Tbody = styled.tbody`
`;

const Tr = styled.tr`
`;

const Td = styled.td`
  padding: 0.25rem 1rem;
  text-align: center;
  vertical-align: middle;
  box-sizing: content-box;
`;

const TdAnswer = styled.td`
  padding: 0.25rem 1rem;
  text-align: center;
  vertical-align: middle;
  max-width: 5rem;
  overflow: hidden;
`

const TdJudge = styled(Td)`
  width: 5rem;
`

const DivResultWrap = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivQuestionNum = styled.div`
  font-size: 1.25rem
`;
