import styled from "styled-components";
import { TEXT_SECONDARY } from "../../config/Constants";

export const PTextSecondaryM = styled.p`
  color: ${TEXT_SECONDARY};
  font-size: 1rem;
`

export const PTextSecondaryL = styled(PTextSecondaryM)`
  font-size: 1.125rem;
`

export const PTextSecondaryXL = styled(PTextSecondaryM)`
  font-size: 1.25rem;
`

export const PTextSecondaryXXL = styled(PTextSecondaryM)`
  font-size: 1.375rem;
`

export const PTextSecondaryS = styled(PTextSecondaryM)`
  font-size: 0.875rem;
`

export const PTextSecondaryXS = styled(PTextSecondaryM)`
  font-size: 0.75rem;
`

export const PTextSecondaryCenXS = styled(PTextSecondaryXS)`
  text-align: center;
`
export const PTextSecondaryCenS = styled(PTextSecondaryS)`
  text-align: center;
`
export const PTextSecondaryCenM = styled(PTextSecondaryM)`
  text-align: center;
`
export const PTextSecondaryCenL = styled(PTextSecondaryL)`
  text-align: center;
`
export const PTextSecondaryCenXL = styled(PTextSecondaryXL)`
  text-align: center;
`
export const PTextSecondaryCenXXL = styled(PTextSecondaryXXL)`
  text-align: center;
`

export const SpanTextSecondaryXS = styled.span`
  color: ${TEXT_SECONDARY};
  font-size: 0.75rem;
`

export const SpanTextSecondaryS = styled.span`
  color: ${TEXT_SECONDARY};
  font-size: 0.875rem;
`

export const SpanTextSecondaryM = styled.span`
  color: ${TEXT_SECONDARY};
  font-size: 1rem;
`
