import React, { createContext } from 'react';
import ReactDOM from 'react-dom';

export type CurrentUser = {
  id: number | null;
  userName: string | null;
  isActivated: boolean;
  isChallengeable?: boolean;
  schoolGrade?: number|null;
  examYear?: number|null,
  prefecture?: string|null;
  prefectureValue?: number;
  email?: string;
  remainChallengeCount?: number;
  premiumPlanEndAt?: string|null;
  isPremiumPlan?: boolean;
  isPremiumPlanExtensionPermit?: boolean;
  isDrillSemiMember?: boolean;
  haveStripeCustomerId?: boolean;
}

const initCurrentUser:CurrentUser = {
  id: null,
  userName: null,
  isActivated: false
}

export const CurrentUserContext = createContext<CurrentUser>(initCurrentUser);
