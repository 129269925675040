import React, { FC } from "react";
import styled from 'styled-components';
import { INCORRECT_BLUE } from '../../config/Constants';
type Props = {
  remSize: number,
  showText?: boolean,
  countText?: string | null
}

export const InCorrectCrossMark:FC<Props> = (props: Props) => {
  const { remSize, showText = true, countText = null } = props;

  const inCorrectCrossMarkRemSizeCss = (remSize:number) => {
    return {width: `${remSize * 1.41}rem`, height: `${remSize * 0.28}rem`}
  }

  const textRemSizeCss = (remSize:number) => {
    return {fontSize: `${remSize}rem`,  }
  }

  const wrapRemSize = (remSize:number) => {
    return {height: `${remSize}rem`, lineHeight: `${remSize}rem`  }
  }

  return(
    <DivWrap style={wrapRemSize(remSize)}>
      <DivInCorrectCrossMark style={inCorrectCrossMarkRemSizeCss(remSize)}/>
      {
        showText && <SpanText style={textRemSizeCss(remSize)}>
          不正解
        </SpanText>
      }
      {
        countText != null && <SpanCount style={textRemSizeCss(remSize * 1.25)}>
          { countText }
        </SpanCount>
      }
    </DivWrap>
  )
}

const SpanCount = styled.span`
  color: ${INCORRECT_BLUE};
  margin-left: -0.25rem;
`

const DivWrap = styled.div`
  display: flex;
  align-items: center;
`;


const DivInCorrectCrossMark = styled.div`
  margin-right: 0.5rem;
  position: relative;
  display: inline-block;
  background: ${INCORRECT_BLUE};
  transform: rotate(45deg);
  vertical-align: middle;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${INCORRECT_BLUE};
    transform: rotate(90deg);
  }
`;

const SpanText = styled.span`
  color: ${INCORRECT_BLUE};
`
