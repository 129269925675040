import React, { FC } from "react";
import styled, { keyframes } from 'styled-components';
import { CurrentUser } from '../../../providers/currentUser';
import { DivSecondaryOrangeButtonDisable, DivSecondaryOrangeButton } from '../../../components/utility/buttons';
import { createPracticeAnswerSet } from "../../../actions/actions";
import { DivFlexCenCen } from "../../../components/utility/flex";
import { PTextSecondaryXS } from "../../../components/utility/textSecondary";
import { ALink } from "../../../components/utility/link";
import { QuestionSet } from "../../../types/questionSet";
import { DivWrap } from "../../../components/utility/wrap";

type Props = {
  questionSet: QuestionSet;
  currentUser: CurrentUser;
}

export const PracticeButton:FC<Props> = (props: Props) => {
  const { questionSet, currentUser } = props;

  // 未ログインユーザー
  if(!currentUser.isActivated) {
    return(
      <>
        <DivWrap>
          <DivFlexCenCen>
            <DivButtonWrap>
              <DivSecondaryOrangeButtonDisable>練習する</DivSecondaryOrangeButtonDisable>
            </DivButtonWrap>
          </DivFlexCenCen>
          <DivFlexCenCen style={{marginTop: '0.5rem'}}>
            <PTextSecondaryXS>会員登録(無料)すると練習できます。</PTextSecondaryXS>
          </DivFlexCenCen>
        </DivWrap>
      </>
    )
  }

  // 以下、ユーザーはログイン済み
  return(
    <>
      <DivFlexCenCen>
        <DivButtonWrap>
          <DivSecondaryOrangeButton onClick={() => createPracticeAnswerSet(questionSet.id)}>練習する</DivSecondaryOrangeButton>
        </DivButtonWrap>
      </DivFlexCenCen>
    </>
  )
}

const DivButtonWrap = styled.div`
  width: 12rem;
`;
