import { useState } from "react";

export const useEmailValidationCheck = () => {
  const [emailValidateMessage, setEmailValidateMessage] = useState<string>('');
  const [isValidateEmail, setIsValidateEmail] = useState<boolean>(false);


  const emailValidationCheck:React.FormEventHandler<HTMLFormElement> = (e) => {
    if (e.target.value.length === 0) {
      setEmailValidateMessage('');
      setIsValidateEmail(false);
      return;
    }

    const reg = new RegExp(/[A-Za-z0-9\-\.\_]+@[A-Za-z0-9\-\_]+\.[A-Za-z0-9\-\.\_]+/);
    if (!reg.test(e.target.value)) {
      setEmailValidateMessage('メールアドレスの形式が間違っています。')
      setIsValidateEmail(false);
    } else {
      setEmailValidateMessage('');
      setIsValidateEmail(true);
    }
  }

  return { isValidateEmail, emailValidateMessage, emailValidationCheck };
}
