import React, { FC } from "react";
import styled from 'styled-components';
import { ALink } from "../utility/link";
type Props = {
  text: string,
  backWord?: string,
  backLinkPath?: string,
  iClassName?: string,
  positionSticky?: boolean;
  backgroundColor?: string;
}

export const PageTitle:FC<Props> = (props: Props) => {
  const { text, backWord, backLinkPath, iClassName, positionSticky = true, backgroundColor = 'white' } = props;

  const position = () => {
    if(positionSticky) {
      return 'sticky'
    } else {
      return 'static'
    }
  };

  return(
    <>
      <DivTitleWrap style={{position: position(), backgroundColor: backgroundColor}}>
        {
          backWord != null && backLinkPath != null
          ? <DivLeft>
              <span>{backWord}
              {
                iClassName != null
                ? <i className={iClassName} style={{marginLeft: '0.5rem'}}/>
                : null
              }
              </span>
            </DivLeft>
          : <span></span>
        }
        <H1Tile>
          { text }
        </H1Tile>
        {
          backWord != null && backLinkPath != null
          ? <DivRight>
              <ALink href={backLinkPath}>{backWord}
              {
                iClassName != null
                ? <i className={iClassName} style={{marginLeft: '0.5rem'}}/>
                : null
              }
              </ALink>
            </DivRight>
          : <span></span>
        }
      </DivTitleWrap>
    </>
  )
}

const DivTitleWrap = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #b5b4b4;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const H1Tile = styled.h1`
  text-align: center;
  font-size: 1.25rem;
`

const DivRight = styled.div`
`

const DivLeft = styled.div`
  visibility: hidden;
`
