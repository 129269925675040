import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import { ORANGE_PRIMARY, TEXT_PRIMARY, TEXT_SECONDARY } from "../../config/Constants";
import { ResultAnswerSet } from "../../types/resultAnswerSet";
import { DivFlex, DivFlexSpbet } from "../utility/flex";
import { DivWrap } from "../utility/wrap";
import { GradeImg } from "./gradeImg";
import { SecToMin } from "./secToMin";

type Props = {
  resultAnswerSet: ResultAnswerSet;
}

export const AnswerSetHistoryWithBar:FC<Props> = (props: Props) => {
  const { resultAnswerSet } = props;

  return(
    <>
      <DivRankingWrap>
        <DivContentWrap>
          <DivFlexSpbet>
            <DivDatetime>{resultAnswerSet.datetime}</DivDatetime>
            <DivFlex>
              <SecToMin sec={resultAnswerSet.timeSec} remSize={1}/>
              <DivScore>
                {resultAnswerSet.score}
                <SpanSmall>点</SpanSmall>
              </DivScore>
            </DivFlex>
          </DivFlexSpbet>
        </DivContentWrap>
      </DivRankingWrap>
    </>
  )
}

const DivRankingWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
`;


const DivContentWrap = styled.div`
  width: 100%;
`
const DivScore = styled.div`
  text-align: right;
  font-size: 1rem;
  margin: 0 0.25rem 0 0;
  min-width: 2.5rem;
`

const DivDatetime = styled.div`
  color: ${TEXT_SECONDARY};
  font-size: 0.75rem;
  text-align: left;
  margin-right: 0;
`;

const SpanWrap = styled.span`
`

const SpanSmall = styled.span`
  color: ${TEXT_SECONDARY};
  font-size: 0.625rem;
  vertical-align: bottom;
`;
