import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { UserComplete } from './userComplete';

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-users-complete-root')!;
  if(domToBinding == null) return

  const props = JSON.parse(domToBinding.dataset.json!);

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <UserComplete {...props}/>
      </DivOverallWrap>
    </>,
    domToBinding);
});
