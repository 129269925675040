import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { QuestionSetShow } from './questionSetShow'
import { CurrentUser, CurrentUserContext } from '../../../providers/currentUser';
import { QuestionSet } from '../../../types/questionSet';
import { QuestionSets } from '../index/questionSets';
import { Grade } from '../../../types/grade';
import { BestTimeAndGrade } from '../../../types/bestTimeAndGrade';
import { Contest } from '../../../types/contest';

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('question-sets-show-root')!;
  if(domToBinding == null) return

  const currentUser:CurrentUser = JSON.parse(domToBinding.dataset.json!).currentUser;
  const questionSet:QuestionSet = JSON.parse(domToBinding.dataset.json!).questionSet;
  const bestTimeAndGrade:BestTimeAndGrade = JSON.parse(domToBinding.dataset.json!).bestTimeAndGrade
  const contests:Contest[] = JSON.parse(domToBinding.dataset.json!).contests
  const notLoginAndDone:boolean = JSON.parse(domToBinding.dataset.json!).notLoginAndDone
  const keisanClubNotionUrl:string = JSON.parse(domToBinding.dataset.json!).keisanClubNotionUrl

  ReactDOM.render(
    <>
      <CurrentUserContext.Provider value={{...currentUser}}>
        <DivOverallWrap>
          <QuestionSetShow questionSet={questionSet} bestTimeAndGrade={bestTimeAndGrade} contests={contests} notLoginAndDone={notLoginAndDone} keisanClubNotionUrl={keisanClubNotionUrl[Symbol]}
          ></QuestionSetShow>
        </DivOverallWrap>
      </CurrentUserContext.Provider>
    </>,
    domToBinding);
});
