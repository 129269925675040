import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { UserNew } from './userNew';


document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-users-new-root')!;
  if(domToBinding == null) return
  const props= JSON.parse(domToBinding.dataset.json!);

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <UserNew {...props}/>
      </DivOverallWrap>
    </>,
    domToBinding);
});
