import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import { PageTitle } from '../../../components/atoms/pageTitle';
import { DivFlexCenCen, DivFlexSpbetCen, DivFlexEnd, DivFlexCen, DivFlexSparo, DivFlexSpbetEnd } from "../../../components/utility/flex";
import { ORANGE_PRIMARY, TEXT_PRIMARY, TEXT_SECONDARY, BORDER_SECONDARY, RED } from "../../../config/Constants";
import { APrimaryOrangeButton, ASecondaryOrangeButton, DivPrimaryOrangeButton, DivSecondaryOrangeButton } from "../../../components/utility/buttons";
import { QuestionSet } from "../../../types/questionSet";
import { Contest } from "../../../types/contest";
import { axios } from '../../../config/Axios';
import { useContext } from "react";
import { CurrentUserContext, CurrentUser } from "../../../providers/currentUser";
import { ALink, SpanLink } from "../../../components/utility/link";
import { DifficultyLabel } from "../../../components/atoms/difficultyLabel";
import { OpinionLinkExternal } from "../../../components/atoms/opinionLinkExternal";
import { CategoryLabel } from "../../../components/atoms/categoryLabel";
import { Modal } from "../../../components/atoms/modal";
import { PTextPrimaryM } from "../../../components/utility/textPrimary";
import { GradeImgAndCounts } from "../../../components/atoms/gradeImageAndCounts";
import { ContestLabel } from "../../../components/atoms/contestLabel";
import { DivStickyWrapTop0 } from "../../../components/utility/stickyWrap";

type Props = {
  questionSets: QuestionSet[];
  contests: Contest[];
}

export const QuestionSets:FC<Props> = (props: Props) => {
  const { questionSets, contests } = props;
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [gradeCounts, setGradeCounts] = useState<GradeCount[]>([])
  const currentUserValue:CurrentUser = useContext(CurrentUserContext)

  useEffect(() => {
    axios.get('/api/grades')
      .then(response => {
        setGradeCounts(response.data.answerSets);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
      });
  }, [])

  const uniqueCategories = () => {
    const categories = questionSets.map((questionSet) => questionSet.category)
    const uniqueCategories = Array.from(new Set(categories.filter((category) => category != null)));

    return uniqueCategories;
  }

  const filterQuestionSet = () => {
    if(selectedCategories.length === 0) {
      return questionSets;
    } else {
      return questionSets.filter((questionSet) => selectedCategories.some((category) => category === questionSet.category))
    }
  }

  const checkFilterCategory = (category: string) => {
    if(selectedCategories.includes(category)) {
      const newSelectedCategories = selectedCategories.filter((selectedCategory) => selectedCategory !== category )
      setSelectedCategories(newSelectedCategories);
    } else {
      const newSelectedCategories = [...selectedCategories, category];
      setSelectedCategories(newSelectedCategories);
    }
  }

  const isCategoryFilterChecked = (category: string) => {
    return selectedCategories.includes(category);
  }

  const categoryFilterCheckedStyle = (category: string) => {
    if(isCategoryFilterChecked(category)) {
      return { color: ORANGE_PRIMARY }
    } else {
      return {}
    }
  }

  return(
    <>
      <DivStickyWrapTop0>
        {
          currentUserValue.isActivated
          ? <PageTitle text={'アプリ学習'}
          backWord={'メニュー'} backLinkPath={'/menu/study'}/>
          : <PageTitle text={'アプリ学習'} backWord={'ログイン'} backLinkPath={'/login'} iClassName={'fas fa-user'}/>
        }
        <DivTabWrapLocal>
          <DivTabElementNotSelected>
            <ANotSelected href={`/calc_question_sets`}>計算道場</ANotSelected>
          </DivTabElementNotSelected>
          <DivTabElementSelected>
            <SpanLinkTabActive>単元学習</SpanLinkTabActive>
          </DivTabElementSelected>
        </DivTabWrapLocal>
      </DivStickyWrapTop0>
      <DivContentWrap>
        {
          currentUserValue.isActivated
          ? null
          : <DivFlexCenCen style={{marginBottom: '2rem'}}>
            <ARegisterOrLogin href={'/users/new'}>会員登録はこちら</ARegisterOrLogin>
          </DivFlexCenCen>
        }
        {/* {
          <DivFlexEnd style={{marginBottom: '2rem'}}>
            <SpanLink style={{textAlign: 'right'}} onClick={() => setIsShowFilterModal(true)}>{ selectedCategories.length > 0 ? '絞り込み中' : '絞り込み'}<i className="fas fa-filter" style={{ marginLeft: '0.25rem'}}/></SpanLink>
          </DivFlexEnd>
        } */}
        {
          filterQuestionSet().map((questionSet, index) =>
            <AQuestionSetWrap key={index} href={`question_sets/${questionSet.id}`}>
              <DivFlexSpbetCen>
                <DivTitlesWrap>
                  <ContestLabel questionSetId={questionSet.id} contests={contests}/>
                  <H3QuestionSetTitle>
                    <span style={{marginRight: '0.25rem'}}>{questionSet.title}</span>
                  </H3QuestionSetTitle>
                  {
                    questionSet.category == null
                    ? null
                    : <CategoryLabel remSize={0.75} category={questionSet.category} />
                  }
                  <span style={{marginLeft: '0.25rem'}}>
                    <DifficultyLabel remSize={0.75} difficulty={questionSet.difficulty}/>
                  </span>
                </DivTitlesWrap>
                {
                  currentUserValue.isActivated
                  ? <GradeImgAndCounts gradeCounts={gradeCounts} questionSetId={questionSet.id} remSize={1}/>
                  : null
                }
              </DivFlexSpbetCen>
            </AQuestionSetWrap>
          )
        }
        <DivFlexEnd style={{marginTop: '3rem'}}>
          <OpinionLinkExternal/>
        </DivFlexEnd>
        <Modal isModalShow={isShowFilterModal}>
            {
              uniqueCategories().map((category, index) =>
                <Labelfilter key={index}>
                  <input
                    type='checkbox'
                    checked={isCategoryFilterChecked(category)}
                    onChange={() => checkFilterCategory(category)}
                  />
                  <PTextPrimaryM style={{marginLeft: '0.25rem',display: 'inline', ...categoryFilterCheckedStyle(category)}}>{category}</PTextPrimaryM>
                </Labelfilter>
              )
            }
            <DivFlexSparo>
            <SpanLink onClick={() => {setSelectedCategories([]);}}>リセット</SpanLink>
            <SpanLink onClick={() => {setIsShowFilterModal(false);}}>閉じる</SpanLink>
            </DivFlexSparo>
        </Modal>
      </DivContentWrap>
    </>
  )
}

const Labelfilter = styled.label`
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  display: inline-block;
`

const DivContentWrap = styled.div`
  padding: 1rem 1rem;
`;

const DivTitlesWrap = styled.div`
  padding-right: 1rem;
`;

const H3QuestionSetTitle = styled.h3`
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  color: ${TEXT_PRIMARY};
`;

const PpremiumPlanText = styled.p`
  font-size: 0.75rem;
  color: ${ORANGE_PRIMARY}
`;

const AQuestionSetWrap = styled.a`
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-bottom: solid 1px ${BORDER_SECONDARY};
  text-decoration: none;
  display: block;
`;

const ARegisterOrLogin = styled(ASecondaryOrangeButton)`
  width: auto;
  padding: 1.5rem 1rem;
`;

const DivTabWrapLocal = styled.div`
  display: flex;
  border-bottom: 1px solid ${BORDER_SECONDARY};
  background-color: #fff;
  margin: 0 0 1rem 0;
  z-index: 102;
`;

const DivTabElementNotSelected = styled.div`
  text-align: center;
  width: 50%;
  color: ${TEXT_SECONDARY};
`;

const DivTabElementSelected = styled.div`
  text-align: center;
  width: 50%;
  border-bottom: 2px solid ${ORANGE_PRIMARY};
  color: ${ORANGE_PRIMARY};
`;

const SpanLinkTabActive = styled.span`
  color: ${ORANGE_PRIMARY};
  text-align: center;
  width: 100%;
  padding: 0.5rem 0;
  display: block;
`;

const ANotSelected = styled.a`
  color: ${TEXT_SECONDARY};
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 0.5rem 0;
  display: block;
`;
