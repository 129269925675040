import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import { PageTitle } from '../../../components/atoms/pageTitle';
import { LINK_BULE, SCHOOL_GRADE_STRING_VALUES, PREFECTURE_STRING_VALUES  } from "../../../config/Constants";
import { axios } from '../../../config/Axios';
import { useContext } from "react";
import { CurrentUserContext, CurrentUser } from "../../../providers/currentUser";
import { ALink } from "../../../components/utility/link";
import { DivFormLabelSet, InputSubmitButton, InputTextField, LabelForTextField } from "../../../components/utility/form";
import { SelectForm } from "../../../components/atoms/selectForm";
import { ErrorMessages } from "../../../components/atoms/errorMessages";
import { ASecondaryOrangeButton } from "../../../components/utility/buttons";

export const BasicInfoEdit:FC = () => {
  const currentUser:CurrentUser = useContext(CurrentUserContext)
  const [inValidMessages, setInValidMessages] = useState<string[]>([])
  const [schoolGradeStrngValues, setSchoolGradeStringValues] =  useState(SCHOOL_GRADE_STRING_VALUES);
  const [prefectureStringValues, setPrefectureStringValues] = useState<(string|number)[][]>(PREFECTURE_STRING_VALUES);
  const [schoolGradeValue, setSchoolGradeValue] = useState<number|null>(currentUser.schoolGrade);
  const [prefectureValue, setPrefectureValue] = useState<number|null>(currentUser.prefectureValue);

  useEffect(() => {
    // セレクトボックスのpromptの削除するかどうかの判定
    const newPrefectureStringValues = [...prefectureStringValues];
    if (currentUser.prefecture != null) {
      newPrefectureStringValues.shift();
      setPrefectureStringValues(newPrefectureStringValues);
    }
    const newSchoolGradeStrngValues = [...schoolGradeStrngValues];
    if (currentUser.schoolGrade != null) {
      newSchoolGradeStrngValues.shift();
      setSchoolGradeStringValues(newSchoolGradeStrngValues);
    }
  }, []);

  const updateBasicInfo:React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    axios.patch('/api/users', {
      user: {
        name: e.target.name.value,
        schoolGrade: e.target.schoolGrade.value,
        prefecture: e.target.prefecture.value
      }
    })
      .then(response => {
        if(response.data.invalidMessages.length === 0){
          window.location.href = `/menu/other`;
        } else {
          setInValidMessages(response.data.invalidMessages);
        }
      })
      .catch(error => {
        console.log(error);
      })
  };

  const prefectureBlankDelete:React.FormEventHandler<HTMLFormElement> = (e) => {
    const newPrefectureStringValues = [...prefectureStringValues];
    if (e.target.value != null && newPrefectureStringValues[0][1] == null) {
      newPrefectureStringValues.shift();
      setPrefectureStringValues(newPrefectureStringValues);
    }
    setPrefectureValue(e.target.value);
  };

  const schoolGradeBlankDelete:React.FormEventHandler<HTMLFormElement> = (e) => {
    const newSchoolGradeStrngValues = [...schoolGradeStrngValues];
    if (e.target.value != null && newSchoolGradeStrngValues[0][1] == null) {
      newSchoolGradeStrngValues.shift();
      setSchoolGradeStringValues(newSchoolGradeStrngValues);
    }
    setSchoolGradeValue(e.target.value);
  };

  return(
    <>
      {
        currentUser.prefecture == null
        ? <PageTitle text={'登録ありがとうございます!'}/>
        : <PageTitle text={'プロフィール編集'}/>
      }
      <FormWrap onSubmit={updateBasicInfo}>
      <DivFormLabelSet>
          <LabelForTextField htmlFor={'name'}>ニックネーム</LabelForTextField>
          <InputTextField name={'name'} defaultValue={currentUser.userName.replace(/^名無し$/, '')}/>
        </DivFormLabelSet>
        <DivFormLabelSet>
          <LabelForTextField htmlFor={'schoolGrade'}>学年</LabelForTextField>
          <SelectForm
            name={'schoolGrade'}
            stringValues={schoolGradeStrngValues}
            onChangeAction={schoolGradeBlankDelete}
            value={schoolGradeValue}
          />
        </DivFormLabelSet>
        <DivFormLabelSet>
          <LabelForTextField htmlFor={'prefecture'}>都道府県</LabelForTextField>
          <SelectForm
            name={'prefecture'}
            stringValues={prefectureStringValues}
            onChangeAction={prefectureBlankDelete}
            value={prefectureValue}
          />
        </DivFormLabelSet>
        <InputSubmitButton style={{marginTop: '3rem'}} type={'submit'} value={'登録する'}/>
        {
          currentUser.prefecture != null
          && <ASecondaryOrangeButton style={{marginTop: '2rem', maxHeight: '2.5rem'}} href={'/menu/other'}> キャンセル </ASecondaryOrangeButton>
        }
      </FormWrap>
      <ErrorMessages errorMessages={inValidMessages}/>
    </>
  )
}

const FormWrap = styled.form`
  padding: 0 1rem;
`;

const ALogout = styled.a`
  color: ${LINK_BULE};
`

