import React, { FC } from "react";
import styled from 'styled-components';
import { RED } from '../../config/Constants';
import { DivFlexCenCen } from '../utility/flex';
import { Grade } from "../../types/grade";
import s_gradeImagePath from '../../../images/grade/S.svg';
import a_gradeImagePath from '../../../images/grade/A.svg';
import b_gradeImagePath from '../../../images/grade/B.svg';
import c_gradeImagePath from '../../../images/grade/C.svg';
import d_gradeImagePath from '../../../images/grade/D.svg';
import e_gradeImagePath from '../../../images/grade/E.svg';

type Props = {
  grade: Grade;
  remSize: number;
}

export const GradeImg:FC<Props> = (props: Props) => {
  const { grade, remSize } = props;

  const gradeImagePath = (grade: Grade) => {
    if (grade === 60) {
      return s_gradeImagePath
    } else if (grade === 50) {
      return a_gradeImagePath
    } else if (grade === 40) {
      return b_gradeImagePath
    } else if (grade === 30) {
      return c_gradeImagePath
    } else if (grade === 20) {
      return d_gradeImagePath
    } else if (grade === 10) {
      return e_gradeImagePath
    } else {
      ''
    }
  }

  return(
    <>
      <img src={gradeImagePath(grade)} style={{width: `${remSize}rem`}}/>
    </>
  )
}
