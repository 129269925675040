
import React, { FC } from "react";
import styled, { keyframes } from 'styled-components';
import { ORANGE_SECONDARY } from "../../config/Constants";


type Props = {
  remSize: number,
}

export const LoaderInnerBallPulse:FC<Props> = (props: Props) => {
  const { remSize } = props;

  const remSizeCss = (remSize:number) => {
    return {width: `${remSize}rem`, height: `${remSize}rem`}
  }

  return(
      <DivLoader>
        <DivBallPluseWrap>
          <DivBallPluseFirst style={remSizeCss(remSize)}/>
          <DivBallPluseSecond style={remSizeCss(remSize)} />
          <DivBallPluseThird style={remSizeCss(remSize)} />
        </DivBallPluseWrap>
      </DivLoader>
    )
}

const DivLoader = styled.div`
`
const DivBallPluseWrap = styled.div`
  text-align: center;
`

const skBouncedelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0.3);
    opacity: 0.3;
  } 40% {
    transform: scale(1.0);
    opacity: 1;
  }
`;


const DivBallPluse = styled.div`
  background-color: ${ORANGE_SECONDARY};
  border-radius: 100%;
  display: inline-block;
  animation: ${skBouncedelay} 1.4s infinite ease-in-out both;
`

const DivBallPluseFirst = styled(DivBallPluse)`
  animation-delay: -0.32s;
`;

const DivBallPluseSecond = styled(DivBallPluse)`
  animation-delay: -0.16s;
`;

const DivBallPluseThird = styled(DivBallPluse)`
`;
