import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/atoms/pageTitle";
import  { BORDER, ORANGE_PRIMARY, TEXT_PRIMARY, TEXT_SECONDARY } from '../../../config/Constants';
import { axios } from '../../../config/Axios';
import { DivFlexCenCen } from "../../../components/utility/flex";
import { ALink } from "../../../components/utility/link";
import { ErrorMessages } from "../../../components/atoms/errorMessages";

export const ResetPasword:FC = () => {
  const [inValidMessages, setInValidMessages] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const resetPasswordSubmit:React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    axios.post('/api/users/reset_password', {
      email: (e.target as any).email.value,
    })
      .then(response => {
        if(response.data.invalidMessages.length === 0) {
          setIsSuccess(true);
        } else {
          setInValidMessages(response.data.invalidMessages);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return(
    <>
      <PageTitle text={'パスワード再設定'}/>
      <DivSignUpWrap>
        <PDescription>パスワードを忘れてログインできない方は、こちらからパスワードを再設定できます。</PDescription>
        <FormSignUp onSubmit={resetPasswordSubmit}>
          <DivFormLabelSet>
            <LabelForTextField>メールアドレス</LabelForTextField>
            <InputTextField
              type={'email'}
              name={'email'}
            />
          </DivFormLabelSet>
          {
          isSuccess
          ? <PSuccessText>パスワード再設定用のメールを送信しました。ご確認ください。</PSuccessText>
          : <InputButtonUserRegister
            type={'submit'}
            value={'再設定のメールを送信'}
          />
          }
        </FormSignUp>
        <ErrorMessages errorMessages={inValidMessages}/>
        <DivFlexCenCen style={{marginTop: '2rem'}}>
          <ALink href={'/users/new'}>会員登録はこちら</ALink>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginTop: '1rem'}}>
          <ALink href={'/login'}>ログインはこちら</ALink>
        </DivFlexCenCen>
      </DivSignUpWrap>
    </>
  )
}

const PSuccessText = styled.p`
  padding: 1rem;
  color: ${TEXT_PRIMARY};
  font-size: 1.25rem;
`

const PDescription = styled.p`
  color: ${TEXT_SECONDARY};
  font-size: 1rem;
`

const DivSignUpWrap = styled.div`
  padding: 2rem 1rem;
`

const DivFormLabelSet = styled.div`
  margin: 1.5rem 0 0 0;
`

const FormSignUp = styled.form`
`

const LabelForTextField = styled.label`
  font-size: 1.125rem;
  display: block;
  color: ${TEXT_PRIMARY}
`

const InputTextField = styled.input`
  box-sizing: border-box;
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`

const InputButtonUserRegister = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  width: 100%;
  background-color: ${ORANGE_PRIMARY};
  color: #fff;
  border: 1px solid ${ORANGE_PRIMARY};
  &:disabled{
    opacity: 0.3;
  }
`;
