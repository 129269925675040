import { string } from "prop-types";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/atoms/pageTitle";
import { DivOverallWrap, DivWrap } from "../../../components/utility/wrap";
import  { BORDER, ORANGE_PRIMARY, TEXT_PRIMARY, TEXT_SECONDARY, RED, GREEN, ORANGE_SHADOW, LIGHT_GRAY, SCHOOL_GRADE_STRING_VALUES } from '../../../config/Constants';
import { axios } from '../../../config/Axios';
import { DivFlexCenCen } from "../../../components/utility/flex";
import { ALink } from "../../../components/utility/link";
import { passwordValidationCheckAndReturnError } from '../../../functions/passwordValidationCheckAndReturnError';
import { DivFormLabelSet, LabelForTextField, InputTextField, InputSubmitButton, PErrorMessage, PValidMessage, SelectField } from '../../../components/utility/form';
import { LegalConfirm } from '../../../components/modules/legalConfirm';
import { useEmailValidationCheck } from '../../../hooks/useEmailValidationCheck';
import { PTextPrimaryS, PTextPrimaryXS } from "../../../components/utility/textPrimary";
import { PTextSecondaryCenS, PTextSecondaryS } from "../../../components/utility/textSecondary";
import { SelectForm } from "../../../components/atoms/selectForm";
import { schoolGradeToString } from "../../../functions/schoolGrade";

type Props = {
  email: string;
  intentions: [string,string][];
  selectedReferrers: [string,string][];
}

export const UserNew:FC<Props>= (props: Props) => {
  const { email, intentions, selectedReferrers } = props;

  const { isValidateEmail, emailValidateMessage, emailValidationCheck } = useEmailValidationCheck();
  const [passwordValidateMessage, setPasswordValidateMessage] = useState<string>('')
  const [isValidatePassword, setIsValidatePassword] = useState<boolean>(false)
  const [signUpFailedMessages, setSignUpFailedMessages] = useState<string[]>([])
  const [submitText, setSubmitText] = useState<string>('登録する')
  const [schoolGradeStringValues, setSchoolGradeStringValues] =  useState(SCHOOL_GRADE_STRING_VALUES);
  const [schoolGradeValue, setSchoolGradeValue] = useState<number|null>(null);
  const [selectedReferrerValues, setSelectedReferrerValues] =  useState(selectedReferrers);
  const [selectedReferrerValue, setSelectedReferrerValue] = useState<string|null>(null);
  const [intentionValues, setIntentionValues] =  useState(intentions);
  const [intentionValue, setIntentionValue] = useState<string|null>(null);

  const passwordValidationCheck:React.FormEventHandler<HTMLFormElement> = (e) => {
    if (e.target.value.length === 0) {
      setPasswordValidateMessage('');
      setIsValidatePassword(false);
      return;
    }

    const error_text = passwordValidationCheckAndReturnError(e.target.value)

    if(error_text.length > 0) {
      setPasswordValidateMessage(error_text)
      setIsValidatePassword(false);
    } else {
      setPasswordValidateMessage('')
      setIsValidatePassword(true);
    }
  }

  const schoolGradeBlankDelete:React.FormEventHandler<HTMLFormElement> = (e) => {
    const newSchoolGradeStringValues = [...schoolGradeStringValues];
    if (e.target.value != null && newSchoolGradeStringValues[0][1] == null) {
      newSchoolGradeStringValues.shift();
      setSchoolGradeStringValues(newSchoolGradeStringValues);
    }
    setSchoolGradeValue(e.target.value);
  };

  const intentionBlankDelete:React.FormEventHandler<HTMLFormElement> = (e) => {
    const newIntentionStringValues = [...intentions];
    console.log(newIntentionStringValues);
    if (e.target.value != null && newIntentionStringValues[0][1] == null) {
      newIntentionStringValues.shift();
      setIntentionValues(newIntentionStringValues);
    }
    setIntentionValue(e.target.value);
  }

  const selectedReferrerBlankDelete:React.FormEventHandler<HTMLFormElement> = (e) => {
    const newSelectedReferrers = [...selectedReferrers];
    if(e.target.value != null && newSelectedReferrers[0][1] == null) {
      newSelectedReferrers.shift();
      setSelectedReferrerValues(newSelectedReferrers);
    }
    setSelectedReferrerValue(e.target.value);
  }


  const signUpSubmit:React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setSubmitText('送信中...')
    setSignUpFailedMessages([]);
    axios.post('/api/users/sign_up', {
      password: (e.target as any).password.value,
      email: (e.target as any).email.value,
      selectedReferrer: selectedReferrerValue,
      intention: intentionValue,
      schoolGrade: schoolGradeValue
    })
      .then(response => {
        if(response.data.invalidMessages.length === 0){
          setSignUpFailedMessages(response.data.invalidMessages);
          window.location.href = `/sign_up/complete`;
        } else {
          setSignUpFailedMessages(response.data.invalidMessages);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(()=>{
        setSubmitText('登録する')
      });
  };

  const isSubmittable = () => {
    return (isValidateEmail && isValidatePassword && selectedReferrerValue != null && intentionValue != null && schoolGradeValue != null)
  }

  return(
    <>
      <DivUserNewTitleWrap>
        <DivOrangeLabel>無料</DivOrangeLabel>
        <H1Tile>会員登録</H1Tile>
      </DivUserNewTitleWrap>
      <DivWrap style={{padding: '1rem 0.5rem 0'}}>
        <LegalConfirm/>
      </DivWrap>
      <DivSignUpWrap>
        <FormSignUp onSubmit={signUpSubmit}>
          <DivFormLabelSet>
            <LabelForTextField htmlFor={'email'}>メールアドレス</LabelForTextField>
            <InputTextField
              type={'email'}
              name={'email'}
              onChange={emailValidationCheck}
            />
          </DivFormLabelSet>
          {
            isValidateEmail
            ? <PValidMessage>OK!</PValidMessage>
            : <PErrorMessage>{emailValidateMessage}</PErrorMessage>
          }
          <DivFormLabelSet>
            <LabelForTextField htmlFor={'password'}>
              パスワード<SpanLabelNote>（英数字8文字以上）</SpanLabelNote>
            </LabelForTextField>
            <InputTextField
              type={'password'}
              name={'password'}
              onChange={passwordValidationCheck}
              autoComplete={'new-password'}
            />
          </DivFormLabelSet>
          {
            isValidatePassword
            ? <PValidMessage>OK!</PValidMessage>
            : <PErrorMessage>{passwordValidateMessage}</PErrorMessage>
          }
          <DivFormLabelSet>
            <LabelForTextField htmlFor={'selectedReferrer'}>
              当サービスをどこでお知りになりましたか？
            </LabelForTextField>
            <SelectForm
              name={'selectedReferrer'}
              stringValues={selectedReferrerValues}
              onChangeAction={selectedReferrerBlankDelete}
              value={selectedReferrerValue}
            />
          </DivFormLabelSet>
          {
            selectedReferrerValue !== null
            && <PValidMessage>OK!</PValidMessage>
          }
          <DivFormLabelSet>
            <LabelForTextField htmlFor={'intention'} style={{marginBottom: '0.25rem'}}>
              ドリる算数で目指したいこと
            </LabelForTextField>
            <PTextSecondaryS style={{marginBottom: '0.5rem'}}>
              一番のお悩みを1つだけお選びください
            </PTextSecondaryS>
            <SelectForm
              name={'intention'}
              stringValues={intentionValues}
              onChangeAction={intentionBlankDelete}
              value={intentionValue}
            />
          </DivFormLabelSet>
          {
            intentionValue !== null
            && <PValidMessage>OK!</PValidMessage>
          }
          <DivFormLabelSet>
            <LabelForTextField htmlFor={'schoolGrade'}>
              お子さまの学年
            </LabelForTextField>
            <SelectForm
              name={'schoolGrade'}
              stringValues={schoolGradeStringValues}
              onChangeAction={schoolGradeBlankDelete}
              value={schoolGradeValue}
            />
            {
              schoolGradeValue !== null
              && <PValidMessage>OK!</PValidMessage>
            }
          </DivFormLabelSet>
          <InputSubmitButton
            type={'submit'}
            value={submitText}
            disabled={!isSubmittable()}
          />
        </FormSignUp>
        {
          signUpFailedMessages.map((message, index) =>
            <DivFlexCenCen style={{margin: '1rem 0'}} key={index}>
              <PErrorMessage>{message}</PErrorMessage>
            </DivFlexCenCen>
          )
        }
        <DivFlexCenCen style={{marginTop: '2rem'}}>
          <ALink href={'/login'}>すでにアカウントをお持ちの方</ALink>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginTop: '1.5rem'}}>
          <ALink href={'/users/reactivate'}>メールアドレスの認証メール再送信</ALink>
        </DivFlexCenCen>
        <PTextPrimaryXS style={{marginTop: '2rem'}}>docomo・au・Softbankなどのキャリアメールを利用されている場合、セキュリティ設定によりキャリアメール以外の受信が拒否されている場合がございます。以下のメールを受信できるように設定をお願い致します。
        </PTextPrimaryXS>
        <DivShadowBox style={{marginTop: '1rem'}}>
          {email}
        </DivShadowBox>
      </DivSignUpWrap>
    </>
  )
}

const DivSignUpWrap = styled.div`
  padding: 0.5rem 1rem 2rem;
`

const DivUserNewTitleWrap = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #b5b4b4;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const H1Tile = styled.h1`
  font-size: 1.25rem;
`

const DivOrangeLabel = styled.div`
  background-color: ${ORANGE_SHADOW};
  color: ${ORANGE_PRIMARY};
  font-size: 1rem;
  padding: 0.125rem 0.5rem;
  margin-right: 0.5rem;
`


const FormSignUp = styled.form`
`

const SpanLabelNote = styled.span`
  font-size: 0.875rem;
  color: ${TEXT_SECONDARY};
`

const DivShadowBox = styled.div`
  padding: 0.5rem;
  text-align: center;
  background-color: ${LIGHT_GRAY};
  border-radius: 0.25rem;
`;
