import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { CurrentUser, CurrentUserContext } from '../../../providers/currentUser';
import { BasicInfoEdit } from './basicInfoEdit'

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-users-edit-root')!;
  if(domToBinding == null) return

  const currentUser:CurrentUser = JSON.parse(domToBinding.dataset.json!).currentUser;

  ReactDOM.render(
    <>
      <CurrentUserContext.Provider value={{...currentUser}}>
        <DivOverallWrap>
          <BasicInfoEdit/>
        </DivOverallWrap>
      </CurrentUserContext.Provider>
    </>,
    domToBinding);
});
