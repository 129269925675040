export const RESPONSIVE_SIZE_SP = 768;
export const TEXT_PRIMARY = '#25292e';
export const TEXT_SECONDARY = '#596068';
export const ORANGE_RED = '#E91912';

export const ORANGE_PRIMARY = '#e96712';
export const ORANGE_SECONDARY = '#e5a149';
export const ORANGE_SHADOW = '#FFE5D4';

export const LINK_BULE = '#2767cf';
export const BORDER = '#b5b4b4';
export const BORDER_SECONDARY = '#f0f0f0';
export const WHITE = '#ffffff';
export const RED = 'red';
export const GREEN = '#019F49';
export const CORRECT_PINK = '#E66B58';
export const INCORRECT_BLUE = '#008CAF';
export const BRONZ = '#6a3805';
export const SILVER = '#b4b4b4';
export const GOLD = '#c9b037';
export const SHADOW = '#f8f8f8'
export const SHADOW_PRIMARY = '#eff1f2'
export const SHADOW_YELLOW = '#FFF5DA';
export const SHADOW_BLUE = '#E4EFFF';
export const SHADOW_GREEN = '#DCFCCD';
export const LABEL_GREEN = '#34C759';
export const GREEN_PRIMARY = '#01AB2C';

export const LIGHT_GRAY = '#F1F3F4'

export const SCHOOL_GRADE_STRING_VALUES = [
  ['選択してください', null],
  ['小学1年生', 1],
  ['小学2年生', 2],
  ['小学3年生', 3],
  ['小学4年生', 4],
  ['小学5年生', 5],
  ['小学6年生', 6],
  ['中学生以上', 7]
];
export const PREFECTURE_STRING_VALUES: [string, number|null][] = [
  ['選択してください', null],
  ['北海道', 1],
  ['青森県', 2],
  ['岩手県', 3],
  ['宮城県', 4],
  ['秋田県', 5],
  ['山形県', 6],
  ['福島県', 7],
  ['茨城県', 8],
  ['栃木県', 9],
  ['群馬県', 10],
  ['埼玉県', 11],
  ['千葉県', 12],
  ['東京都', 13],
  ['神奈川県', 14],
  ['新潟県', 15],
  ['富山県', 16],
  ['石川県', 17],
  ['福井県', 18],
  ['山梨県', 19],
  ['長野県', 20],
  ['岐阜県', 21],
  ['静岡県', 22],
  ['愛知県', 23],
  ['三重県', 24],
  ['滋賀県', 25],
  ['京都府', 26],
  ['大阪府', 27],
  ['兵庫県', 28],
  ['奈良県', 29],
  ['和歌山県', 30],
  ['鳥取県', 31],
  ['島根県', 32],
  ['岡山県', 33],
  ['広島県', 34],
  ['山口県', 35],
  ['徳島県', 36],
  ['香川県', 37],
  ['愛媛県', 38],
  ['高知県', 39],
  ['福岡県', 40],
  ['佐賀県', 41],
  ['長崎県', 42],
  ['熊本県', 43],
  ['大分県', 44],
  ['宮崎県', 45],
  ['鹿児島県', 46],
  ['沖縄県', 47],
  ['その他', 48]
]

export const renponsiveBreakPoint = 768;

// 変更の場合は、constants.rbの値も変更すること
export const MARUTSUKE_CORPORATE_WEBSITE_URL = 'https://marutsuke.com/';
