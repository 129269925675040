export const passwordValidationCheckAndReturnError:(string) => string = (password:string) => {
  let error_texts: string[] = []
  if (password.length < 8) {
    error_texts.push('8文字以上')
  } else if (password.length > 120) {
    error_texts.push('120文字未満')
  }

  const reg = new RegExp(/^([a-zA-Z0-9.?/-_=@]+)$/);
  if (!reg.test(password)) {
    error_texts.push('半角英数字または記号(.?/-_=@)')
  }

  if(error_texts.length > 0) {
    return `${error_texts.join('・')}で入力してください。`
  } else {
    return ''
  }
}
