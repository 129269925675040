import React, { FC } from "react";
import styled from 'styled-components';
import { TEXT_SECONDARY } from "../../config/Constants";

type Props = {
  category: string,
  remSize: number,
}

export const CategoryLabel:FC<Props> = (props: Props) => {
  const { category, remSize } = props;

  return(
    <>
      <SpanLabel style={{fontSize: `${remSize}rem`}}>{category}</SpanLabel>
    </>
  )
}

const SpanLabel = styled.span`
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
  text-align: center;
  min-width: 5rem;
  display: inline-block;
  color: ${TEXT_SECONDARY};
  border: 1px solid ${TEXT_SECONDARY};
`
