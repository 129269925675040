import React, { FC } from "react";
import styled, { keyframes } from 'styled-components';


type Props = {
  answer: string,
  isInputing: boolean;
}

export const AnswerStringToDisplay:FC<Props> = (props: Props) => {
  const { answer, isInputing } = props;

  if((/^\d*と\d*$/).test(answer)){
    const fractionNumArray = answer.match(/^(\d*)と(\d*)/);

    return(
      <>
        <DivAnswerFractionWrap>
          <DivInteger>{fractionNumArray[1]}</DivInteger>
          <DivFraction>
            <DivNumerator/>
            <DivFractionBar/>
            <DivFractionNumWrap>
              <DivDenominatorActive>{fractionNumArray[2]}</DivDenominatorActive>
            </DivFractionNumWrap>
          </DivFraction>
        </DivAnswerFractionWrap>
      </>
    )
  } else if ((/^\d*と\d*分の\d*$/).test(answer)) {
    const fractionNumArray = answer.match(/^(\d*)と(\d*)分の(\d*)/);

    return(
      <>
        <DivAnswerFractionWrap>
          <DivInteger>
            {fractionNumArray[1]}
          </DivInteger>
          <DivFraction>
            <DivFractionNumWrap>
               { isInputing
                ? <DivNumeratorActive>{fractionNumArray[3]}</DivNumeratorActive>
                : <DivNumerator>{fractionNumArray[3]}</DivNumerator>
               }
            </DivFractionNumWrap>
            <DivFractionBar/>
            <DivFractionNumWrap>
              <DivDenominator>{fractionNumArray[2]}</DivDenominator>
            </DivFractionNumWrap>
          </DivFraction>
        </DivAnswerFractionWrap>
      </>
    )
  } else if ((/^(\d*)分の(\d*)$/).test(answer)) {
    const fractionNumArray = answer.match(/^(\d*)分の(\d*)/);
    return(
      <>
        <DivAnswerFractionWrap>
          <DivFraction>
            <DivFractionNumWrap>
              { isInputing
                ? <DivNumeratorActive>{fractionNumArray[2]}</DivNumeratorActive>
                : <DivNumerator>{fractionNumArray[2]}</DivNumerator>
               }
            </DivFractionNumWrap>
            <DivFractionBar/>
            <DivFractionNumWrap>
              <DivDenominator>{fractionNumArray[1]}</DivDenominator>
            </DivFractionNumWrap>
          </DivFraction>
        </DivAnswerFractionWrap>
      </>
    )
  } else if ((/^(\d*)あまり(\d*)$/).test(answer)) {
    const modNumArray = answer.match(/^(\d*)あまり(\d*)/);
    return(
      <>
        <DivAnaswerSpace>
          {
            isInputing
              ? <DivIntegerAnswerWrapActive>{ modNumArray[1] }<SpanMod>あまり</SpanMod> {modNumArray[2]}</DivIntegerAnswerWrapActive>
              : <DivIntegerAnswerWrap>{ modNumArray[1] }<SpanMod>あまり</SpanMod> {modNumArray[2]}</DivIntegerAnswerWrap>
          }
        </DivAnaswerSpace>
      </>
      )
    } else {
    return(
      <DivAnaswerSpace>
        {
          isInputing
          ? <DivIntegerAnswerWrapActive>{ answer }</DivIntegerAnswerWrapActive>
          : <DivIntegerAnswerWrap>{ answer }</DivIntegerAnswerWrap>
        }
      </DivAnaswerSpace>
    )
  }
}

const borderRightBlinking = keyframes`
  0% {
    border-right: 1px solid #444;
  }

  50% {
    border-right: 1px solid #fff;
  }

  100% {
    border-right: 1px solid #444;
  }
`;


const DivAnswerFractionWrap = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
`

const DivInteger = styled.div`
  height: 3.5rem;
  line-height: 4rem;
  margin-right: 0.25rem;
`;

const DivFraction = styled.div`
  height: 3.5rem;
  min-width: 2rem
`;

const DivFractionBar = styled.div`
  height: 0.125rem;
  margin: 0.1875rem 0;
  background-color: #444;
`;

const DivDenominator = styled.div`
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.25rem;
  text-align: center;
`;

const DivDenominatorActive = styled(DivDenominator)`
  animation: 1s ${borderRightBlinking} infinite linear;
`;

const DivFractionNumWrap =styled.div`
  display: flex;
  justify-content: center;
`;

const DivNumerator = styled.div`
  height: 1.75rem;
  padding: 0 0.25rem;
  line-height: 1.75rem;
  text-align: center;
`;

const DivNumeratorActive = styled(DivNumerator)`
  animation: 1s ${borderRightBlinking} infinite linear;
`;

const DivAnaswerSpace = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 0.125rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space:nowrap;
`;

const DivIntegerAnswerWrapActive = styled.div`
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  animation: 1s ${borderRightBlinking} infinite linear;
`

const DivIntegerAnswerWrap = styled.div`
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
`
const SpanMod = styled.span`
  height: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  margin-right: -0.125rem;
`
