import React, { FC } from "react";
import styled from 'styled-components';
import { TEXT_PRIMARY, TEXT_SECONDARY } from "../../config/Constants";
type Props = {
  score: number,
  remSize: number,
}

export const Score:FC<Props> = (props: Props) => {
  const { score, remSize } = props;

  return(
    <>
      <DivWrap>
        <SpanNum style={{fontSize: `${remSize}rem`}}>{score}</SpanNum>
        <Span style={{fontSize: `${remSize/2}rem`}}>点</Span>
      </DivWrap>
    </>
  )
}

const DivWrap = styled.div`
`

const SpanNum = styled.span`
  color: ${TEXT_PRIMARY};
`

const Span = styled.span`
  color: ${TEXT_SECONDARY};
`
