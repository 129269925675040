import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { EditPasswordForm } from './editPasswordForm';

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-reset-password-edit-root')!;
  if(domToBinding == null) return

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <EditPasswordForm />
      </DivOverallWrap>
    </>,
    domToBinding);
});
