import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../components/utility/wrap';
import { QuestionSet } from '../../../types/questionSet';
import { Contest } from '../../../types/contest';
import { QuestionSets } from './questionSets'
import { CurrentUser, CurrentUserContext } from '../../../providers/currentUser';

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('question-sets-index-root')!;
  if(domToBinding == null) return

  const currentUser:CurrentUser = JSON.parse(domToBinding.dataset.json!).currentUser;
  const questionSets:QuestionSet[] = JSON.parse(domToBinding.dataset.json!).questionSets;
  const contests:Contest[] = JSON.parse(domToBinding.dataset.json!).contests;

  ReactDOM.render(
    <>
      <CurrentUserContext.Provider value={{...currentUser}}>
        <DivOverallWrap>
          <QuestionSets questionSets={questionSets} contests={contests} />
        </DivOverallWrap>
      </CurrentUserContext.Provider>
    </>,
    domToBinding);
});
