import { useState, useEffect } from "react";
import { PageInfo } from "../types/pageInfo";
import { ResultAnswerSet } from "../types/resultAnswerSet";
import { axios } from "../config/Axios";

type Props = {
  questionSetId?: number|null;
  initMaxItemsPerPage: number;
}

export const useAnswerSetHistory = (props: Props) => {
  const { questionSetId, initMaxItemsPerPage } = props;
  const [pageInfo, setPageInfo] = useState<PageInfo>({count: 0, page: 0, maxItemsPerPage: initMaxItemsPerPage})
  const [answerSets, setAnswerSets] = useState<ResultAnswerSet[]>([])

  useEffect(() => {
    getAnswerSetHistory()
  }, [])

  const isMoreAnswerSetHistory = () => {
    return (pageInfo.maxItemsPerPage * pageInfo.page < pageInfo.count)
  }

  const getAnswerSetHistory = () => {
    axios.get('/api/answer_sets/history', {
      params: {
        page: pageInfo.page + 1,
        questionSetId: questionSetId,
        maxItemsPerPage: pageInfo.maxItemsPerPage
      }})
      .then(response => {
        if(response.data.answerSets != null) {
          setPageInfo(response.data.pageInfo);
          const newAnswerSets = [...answerSets, ...response.data.answerSets]
          setAnswerSets(newAnswerSets);
        }
      })
  }
  return { answerSets, getAnswerSetHistory, isMoreAnswerSetHistory };
}
