import { string } from "prop-types";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/atoms/pageTitle";
import { DivOverallWrap } from "../../../components/utility/wrap";
import  { BORDER, ORANGE_PRIMARY, TEXT_PRIMARY, TEXT_SECONDARY, RED, GREEN, ORANGE_SHADOW, LIGHT_GRAY } from '../../../config/Constants';
import { DivFlexCenCen } from "../../../components/utility/flex";
import { PTextPrimaryCenL, PTextPrimaryCenM, PTextPrimaryM } from "../../../components/utility/textPrimary";
import { ALink } from "../../../components/utility/link";

type Props = {
  email: string;
}


export const UserComplete:FC<Props> = (props: Props) => {
  const { email } = props;
  return(
    <>
      <PageTitle text={'仮登録完了'}/>
      <DivFlexCenCen style={{marginTop: '2rem'}}>
        <DivEmailIconWrap>
          <i className="far fa-envelope" style={{color: ORANGE_PRIMARY, padding: '1rem', fontSize: ' 3rem'}}/>
        </DivEmailIconWrap>
      </DivFlexCenCen>
      <DivSignUpCompleteWrap>
        <PSignUpCompeleteText>ご登録のメールアドレスに<br/>招待メールを送信しました。</PSignUpCompeleteText>
        <PSignUpCompeleteText>ご確認お願いします。</PSignUpCompeleteText>
        <DivShadowBox>
          <PTextPrimaryCenL style={{marginBottom: '0.625rem'}}>メールが届かない場合</PTextPrimaryCenL>
          <PTextPrimaryCenM style={{marginBottom: '0.625rem'}}>{email}</PTextPrimaryCenM>
          <PTextPrimaryM style={{textAlign: 'left'}}>からのメールを受け取るように設定し、以下のリンクから再度メールを送信するようにお願いします。</PTextPrimaryM>
          <DivFlexCenCen style={{marginTop: '0.625rem'}}>
            <ALink href={'/users/reactivate'} style={{fontSize: '0.875'}}>メールアドレスの認証メール再送信</ALink>
          </DivFlexCenCen>
        </DivShadowBox>
      </DivSignUpCompleteWrap>
    </>
  )
}

const DivEmailIconWrap = styled.div`
  background-color: ${ORANGE_SHADOW};
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`


const DivSignUpCompleteWrap = styled.div`
  margin: 1.5rem 0;
  padding: 0 1rem;
`

const PSignUpCompeleteText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`

const DivShadowBox = styled.div`
  margin-top: 2.5rem;
  padding: 1rem;
  text-align: center;
  background-color: ${LIGHT_GRAY};
  border-radius: 0.25rem;
`;
