import { string } from "prop-types";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/atoms/pageTitle";
import  { BORDER, ORANGE_PRIMARY, ORANGE_SECONDARY, TEXT_PRIMARY } from '../../../config/Constants';
import { axios } from '../../../config/Axios';
import { ErrorMessages } from "../../../components/atoms/errorMessages";
import { passwordValidationCheckAndReturnError } from '../../../functions/passwordValidationCheckAndReturnError';
import { RED, GREEN } from '../../../config/Constants';


export const EditPasswordForm:FC= () => {
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [passwordValidMessage, setPasswordValidMessage] = useState<stirng>('')
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false)

  const passwordValidationCheck:React.FormEventHandler<HTMLFormElement> = (e) => {
    if (e.target.value.length === 0) {
      setPasswordValidMessage('');
      setIsValidPassword(false);
      return;
    }

    const error_text = passwordValidationCheckAndReturnError(e.target.value)

    setPasswordValidMessage(error_text)
    if(error_text.length > 0) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  }

  const passwordUpdateSubmit:React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    axios.patch('/api/users/update_password', {
      password: (e.target as any).password.value,
      passwordConfirmation: (e.target as any).passwordConfirmation.value,
    })
      .then(response => {
        if(response.data.invalidMessages.length === 0) {
          window.location.href = `/menu/other`;
        } else {
          setErrorMessages(response.data.invalidMessages);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return(
    <>
      <PageTitle text={'パスワード再設定'}/>
      <DivSignUpWrap>
        <FormSignUp onSubmit={passwordUpdateSubmit}>
          <DivFormLabelSet>
            <LabelForTextField>新しいパスワード</LabelForTextField>
            <InputTextField
              type={'password'}
              name={'password'}
              onChange={passwordValidationCheck}
            />
          </DivFormLabelSet>
          {
            isValidPassword
            ? <PValidMessage>OK!</PValidMessage>
            : <PErrorMessage>{passwordValidMessage}</PErrorMessage>
          }
          <DivFormLabelSet>
            <LabelForTextField>新しいパスワード(確認)</LabelForTextField>
            <InputTextField
              type={'password'}
              name={'passwordConfirmation'}
            />
          </DivFormLabelSet>
          <InputButtonUserRegister
            type={'submit'}
            value={'登録'}
            disabled={!isValidPassword}
          />
        </FormSignUp>
        <ErrorMessages errorMessages={errorMessages}/>
      </DivSignUpWrap>
    </>
  )
}
const PErrorMessage = styled.p`
  color: ${RED};
`;

const PValidMessage = styled.p`
  color: ${GREEN};
  font-size: 1.25rem;
`

const DivSignUpWrap = styled.div`
  padding: 2rem 1rem;
`

const DivFormLabelSet = styled.div`
  margin: 1.5rem 0 0 0;
`

const FormSignUp = styled.form`
`

const LabelForTextField = styled.label`
  font-size: 1.125rem;
  display: block;
  color: ${TEXT_PRIMARY}
`

const InputTextField = styled.input`
  box-sizing: border-box;
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`

const InputButtonUserRegister = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  width: 100%;
  background-color: ${ORANGE_PRIMARY};
  color: #fff;
  border: 1px solid ${ORANGE_PRIMARY};
  &:disabled{
    opacity: 0.3;
  }
`;
